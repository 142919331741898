import { Icon } from 'glints-aries/lib/@next';
import {
  borderRadius20,
  borderRadiusHalf,
} from 'glints-aries/lib/@next/utilities/borderRadius';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space8, space24 } from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

import { breakpoint } from '@/components/constants';

export const MainContainer = styled.div`
  height: 100%;
`;

export const StyledPageTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid ${Neutral.B85};
  padding: 0 0 ${space24} ${space24};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 180px;

  .status-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 48px;

    .status-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: ${space8};
    }

    .status-content-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  @media (max-width: ${breakpoint.large}) {
    margin-top: 80px;
  }

  @media (max-width: ${breakpoint.medium}) {
    margin-top: 80px;

    .status-content-container {
      gap: ${space24};
    }
  }

  @media (max-width: ${breakpoint.small}) {
    margin-top: 60px;
  }
`;

export const BankIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${space8};
  margin-bottom: ${space24};
`;

export const IconsContainer = styled.div<{ backgroundColor?: string }>`
  position: relative;
  background-color: ${(props) => props.backgroundColor};
  width: 212px;
  height: 76px;
  border-radius: ${borderRadius20};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${space24};

  .icon-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${space8};
  }
`;

export const FloatingIcon = styled(Icon)`
  height: 32px;
  background: white;
  border-radius: ${borderRadiusHalf};
  position: absolute;
  z-index: 2;
  top: -10px;
  right: -16px;
`;
