import { Icon, Typography } from 'glints-aries/lib/@next';

import { MenuItemStyled } from './styled.sc';
import { type Item } from './types';

export const MenuItem = ({
  itemKey,
  label,
  selectedKey,
  iconName,
  selectedIconName,
  isCollapsed,
  isMobile,
  onClick,
}: Item & { itemKey: string; isCollapsed: boolean; isMobile: boolean }) => {
  const isSelected = selectedKey === itemKey;
  const displayIconName =
    isSelected && selectedIconName ? selectedIconName : iconName;

  return (
    <MenuItemStyled
      data-selected={isSelected}
      data-collapsed={isCollapsed}
      data-mobile={isMobile}
      onClick={() => onClick?.(itemKey)}
    >
      {displayIconName && <Icon name={displayIconName} />}
      <Typography as="span" variant="button">
        {label}
      </Typography>
    </MenuItemStyled>
  );
};
