import {
  Breakpoints,
  OutlineButton as GlintsOutlineButton,
} from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space8, space16 } from 'glints-aries/lib/@next/utilities/spacing';
import { styled } from 'styled-components';

export const TableContainer = styled.div`
  box-shadow: 0px 0px 0px 1px rgba(84, 84, 84, 0.2);
  transform: translateX(-24px);
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${Neutral.B100};
  height: calc(100% - 200px);

  @media (max-width: ${Breakpoints.large}) {
    transform: translateX(-16px);
  }
`;

export const TimeOffTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TablePaginationContainer = styled.div`
  width: 100%;
  padding: ${space8} ${space16};
  background: ${Neutral.B100};
  position: sticky;
  bottom: -32px;
  z-index: 10;
`;

export const HubberNameFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HubberNameWrapper = styled.div`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const OutlineButton = styled(GlintsOutlineButton)`
  min-width: unset;
  padding: revert;
`;
