import { GraphQLClient } from 'graphql-request';
import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  NaiveDate: { input: any; output: any; }
  RoleId: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

export type AccountManager = {
  __typename?: 'AccountManager';
  email: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export enum Action {
  Delete = 'DELETE',
  Read = 'READ',
  Write = 'WRITE'
}

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type Attendance = {
  __typename?: 'Attendance';
  clockIn?: Maybe<Scalars['DateTime']['output']>;
  clockOut?: Maybe<Scalars['DateTime']['output']>;
  date: Scalars['NaiveDate']['output'];
  scheduledClockIn?: Maybe<Scalars['DateTime']['output']>;
  scheduledClockOut?: Maybe<Scalars['DateTime']['output']>;
  status: AttendanceStatus;
};

export enum AttendanceSortField {
  Date = 'DATE'
}

export type AttendanceSortInput = {
  field: AttendanceSortField;
  order: SortOrder;
};

export enum AttendanceStatus {
  Absent = 'ABSENT',
  DataIncomplete = 'DATA_INCOMPLETE',
  Holiday = 'HOLIDAY',
  LateIn = 'LATE_IN',
  Present = 'PRESENT',
  TimeOff = 'TIME_OFF'
}

export type AttendanceSummary = {
  __typename?: 'AttendanceSummary';
  actualHours: Scalars['Decimal']['output'];
  data: Array<Attendance>;
  scheduledHours: Scalars['Decimal']['output'];
};


export type AttendanceSummaryDataArgs = {
  sorts?: InputMaybe<Array<AttendanceSortInput>>;
  status?: InputMaybe<Array<AttendanceStatus>>;
};

export type Company = {
  __typename?: 'Company';
  accountManager?: Maybe<AccountManager>;
  billingAddress: Address;
  billingEmail?: Maybe<Scalars['String']['output']>;
  billingName?: Maybe<Scalars['String']['output']>;
  businessCategory?: Maybe<Scalars['String']['output']>;
  contactCount: Scalars['Int']['output'];
  contacts: Array<Contact>;
  directDebit?: Maybe<DirectDebitAuthorization>;
  failedDirectDebit: Array<FailedDirectDebit>;
  hubberHubs: Array<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  operatingAddress: Address;
  registeredAddress: Address;
  registeredName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};


export type CompanyContactCountArgs = {
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['RoleId']['input']>>>;
};


export type CompanyContactsArgs = {
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['RoleId']['input']>>>;
};

export type Contact = {
  __typename?: 'Contact';
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Phone>;
  roles: Array<Role>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ContactInput = {
  department?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  jobTitle: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<PhoneInput>;
  roleId: Array<Scalars['RoleId']['input']>;
};

export type CostStructure = {
  __typename?: 'CostStructure';
  children: Array<CostStructure>;
  descriptionKey: Scalars['String']['output'];
  labelKey: Scalars['String']['output'];
  total: Money;
};

export type CreditNoteLine = {
  __typename?: 'CreditNoteLine';
  amount: Money;
  id: Scalars['UUID']['output'];
  issueDate: Scalars['NaiveDate']['output'];
};

export type DirectDebitAuthorization = {
  __typename?: 'DirectDebitAuthorization';
  activatedAt?: Maybe<Scalars['DateTime']['output']>;
  authorizationUrl: Scalars['String']['output'];
  bankSwiftBicCode: Scalars['String']['output'];
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  failureReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isActive: Scalars['Boolean']['output'];
  nextCollectionDate?: Maybe<Scalars['NaiveDate']['output']>;
  status: DirectDebitStatus;
};

export type DirectDebitCancelation = {
  __typename?: 'DirectDebitCancelation';
  cancelationUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
};

export enum DirectDebitStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export type DisconnectDirectDebitParam = {
  redirectUrl: Scalars['String']['input'];
};

export enum EmploymentStatus {
  Active = 'ACTIVE',
  ContractEnded = 'CONTRACT_ENDED',
  ResignedOrTerminated = 'RESIGNED_OR_TERMINATED'
}

export enum EmploymentType {
  Contract = 'CONTRACT',
  Permanent = 'PERMANENT'
}

export type EstimatedCostParameter = {
  /**
   * Gross salary for the calculation.
   *
   * Currency is being ignored right now, but the interface is designed to be currency-aware.
   */
  grossSalary: MoneyInput;
};

export type ExpenseClaim = {
  __typename?: 'ExpenseClaim';
  amount: Money;
  approval?: Maybe<ExpenseClaimApproval>;
  attachments: Array<ExpenseClaimAttachment>;
  category: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  hubber: Hubber;
  hubberId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  notesToManager?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};


export type ExpenseClaimAmountArgs = {
  currency?: InputMaybe<Scalars['String']['input']>;
};

export type ExpenseClaimApproval = {
  __typename?: 'ExpenseClaimApproval';
  approver: Contact;
  approverId: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  expenseClaimId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  remarks?: Maybe<Scalars['String']['output']>;
  status: ExpenseClaimsStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type ExpenseClaimAttachment = {
  __typename?: 'ExpenseClaimAttachment';
  fileName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
};

export enum ExpenseClaimSortField {
  RequestedAt = 'REQUESTED_AT'
}

export type ExpenseClaimSortInput = {
  field: ExpenseClaimSortField;
  order: SortOrder;
};

export enum ExpenseClaimsStatus {
  Approved = 'APPROVED',
  Rejected = 'REJECTED'
}

export enum ExpenseClaimsStatusFilter {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type Export = {
  __typename?: 'Export';
  fileUrl: Scalars['String']['output'];
};

export type FailedDirectDebit = {
  __typename?: 'FailedDirectDebit';
  lastAttemptedAt: Scalars['DateTime']['output'];
  paymentId: Scalars['String']['output'];
  reason: FailedDirectDebitReason;
};

export enum FailedDirectDebitReason {
  AuthorizationCanceled = 'AUTHORIZATION_CANCELED',
  NotEnoughBalance = 'NOT_ENOUGH_BALANCE'
}

export type File = {
  __typename?: 'File';
  url: Scalars['String']['output'];
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type Hubber = {
  __typename?: 'Hubber';
  attendance: AttendanceSummary;
  contractEndDate?: Maybe<Scalars['NaiveDate']['output']>;
  contractType?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  employmentType: EmploymentType;
  fullName: Scalars['String']['output'];
  hub: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  jobTitle: Scalars['String']['output'];
  joinDate: Scalars['NaiveDate']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  publicHoliday?: Maybe<PublicHoliday>;
  resignDate?: Maybe<Scalars['NaiveDate']['output']>;
  salary?: Maybe<Money>;
  status: EmploymentStatus;
  timeoffBalance: Array<TimeOffBalance>;
};


export type HubberAttendanceArgs = {
  period: Month;
};

export enum HubberSortField {
  FullName = 'FULL_NAME',
  HrmsId = 'HRMS_ID'
}

export type HubberSortInput = {
  field: HubberSortField;
  order: SortOrder;
};

export type InitializeDirectDebitParam = {
  bankSwiftBicCode: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
};

export type Invoice = {
  __typename?: 'Invoice';
  amount: Money;
  closedDate?: Maybe<Scalars['NaiveDate']['output']>;
  creditNoteLines: Array<CreditNoteLine>;
  dispute?: Maybe<InvoiceDispute>;
  dueDate?: Maybe<Scalars['NaiveDate']['output']>;
  id: Scalars['UUID']['output'];
  isPdfReady: Scalars['Boolean']['output'];
  issueDate: Scalars['NaiveDate']['output'];
  paidAmount: Money;
  paymentLines: Array<PaymentLine>;
  platformPayment?: Maybe<PlatformPayment>;
  referenceNumber: Scalars['String']['output'];
  unpaidAmount: Money;
};


export type InvoicePaymentLinesArgs = {
  sorts?: InputMaybe<Array<PaymentLineSortInput>>;
};

export type InvoiceDispute = {
  __typename?: 'InvoiceDispute';
  createdAt: Scalars['DateTime']['output'];
  createdBy: InvoiceDisputeRequester;
  id: Scalars['UUID']['output'];
  invoiceId: Scalars['UUID']['output'];
  reason: Scalars['String']['output'];
};

export type InvoiceDisputeRequester = {
  __typename?: 'InvoiceDisputeRequester';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export enum InvoiceSortField {
  DueDate = 'DUE_DATE',
  Id = 'ID',
  InvoiceNumber = 'INVOICE_NUMBER',
  IssueDate = 'ISSUE_DATE'
}

export type InvoiceSortInput = {
  field: InvoiceSortField;
  order: SortOrder;
};

export type InvoiceSummary = {
  __typename?: 'InvoiceSummary';
  lastMonthTotal: Array<Money>;
  max3Months: Array<Money>;
  open: Scalars['Int']['output'];
  outstanding: Scalars['Int']['output'];
  overdue: Scalars['Int']['output'];
  totalUnpaid: Array<Money>;
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Decimal']['output'];
  currency: Scalars['String']['output'];
  inCurrencies: Array<Money>;
};


export type MoneyInCurrenciesArgs = {
  currency: Array<Scalars['String']['input']>;
};

export type MoneyInput = {
  amount: Scalars['Decimal']['input'];
  currency: Scalars['String']['input'];
};

export type Month = {
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addContact?: Maybe<Contact>;
  approveExpenseClaims: ExpenseClaim;
  approveTimeOffRequest: TimeOffRequest;
  createInvoiceDispute: InvoiceDispute;
  createNewOnboardingRequest: OnboardingRequest;
  createPlatformPayment: PlatformPayment;
  deleteContact: Scalars['Boolean']['output'];
  /**
   * Disconnect direct debit
   * This will remove the direct debit authorization from the company
   */
  disconnectDirectDebit: DirectDebitCancelation;
  expenseClaimAttachmentUrl: File;
  exportAttendanceSummary: Export;
  exportExpenseClaim: Export;
  exportHubberAttendance: Export;
  exportTimeoffBalances: Export;
  exportTimeoffRequests: Export;
  /** Initialize direct debit */
  initializeDirectDebit: DirectDebitAuthorization;
  invoicePdf: File;
  paymentPdf: File;
  rejectExpenseClaims: ExpenseClaim;
  rejectTimeOffRequest: TimeOffRequest;
  resolveMagicLink: Token;
  sendMagicLink: Scalars['Boolean']['output'];
  sendSigningDocuments: OnboardingRequest;
  updateContact?: Maybe<Contact>;
  updateProfile: Contact;
};


export type MutationAddContactArgs = {
  contact: ContactInput;
};


export type MutationApproveExpenseClaimsArgs = {
  expenseClaimId: Scalars['UUID']['input'];
  remarks?: InputMaybe<Scalars['String']['input']>;
};


export type MutationApproveTimeOffRequestArgs = {
  timeOffId: Scalars['UUID']['input'];
};


export type MutationCreateInvoiceDisputeArgs = {
  invoiceId: Scalars['UUID']['input'];
  reason: Scalars['String']['input'];
};


export type MutationCreateNewOnboardingRequestArgs = {
  param: OnboardingRequestInput;
};


export type MutationCreatePlatformPaymentArgs = {
  invoiceIds: Array<Scalars['UUID']['input']>;
  method: PlatformPaymentMethod;
};


export type MutationDeleteContactArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDisconnectDirectDebitArgs = {
  param: DisconnectDirectDebitParam;
};


export type MutationExpenseClaimAttachmentUrlArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationExportAttendanceSummaryArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  period: Month;
  status?: InputMaybe<Array<EmploymentStatus>>;
};


export type MutationExportExpenseClaimArgs = {
  claimStatus?: InputMaybe<Array<ExpenseClaimsStatusFilter>>;
  currencyInput: Scalars['String']['input'];
  hub?: InputMaybe<Array<Scalars['String']['input']>>;
  hubberName?: InputMaybe<Scalars['String']['input']>;
  hubberStatus?: InputMaybe<Array<EmploymentStatus>>;
};


export type MutationExportHubberAttendanceArgs = {
  hubberId: Scalars['UUID']['input'];
  period: Month;
  status?: InputMaybe<Array<AttendanceStatus>>;
};


export type MutationExportTimeoffBalancesArgs = {
  hubberStatus?: InputMaybe<Array<EmploymentStatus>>;
  month: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};


export type MutationExportTimeoffRequestsArgs = {
  hub?: InputMaybe<Array<Scalars['String']['input']>>;
  hubberName?: InputMaybe<Scalars['String']['input']>;
  hubberStatus?: InputMaybe<Array<EmploymentStatus>>;
  requestStatus?: InputMaybe<Array<TimeOffRequestStatus>>;
};


export type MutationInitializeDirectDebitArgs = {
  param: InitializeDirectDebitParam;
};


export type MutationInvoicePdfArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationPaymentPdfArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationRejectExpenseClaimsArgs = {
  expenseClaimId: Scalars['UUID']['input'];
  remarks?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRejectTimeOffRequestArgs = {
  remark?: InputMaybe<Scalars['String']['input']>;
  timeOffId: Scalars['UUID']['input'];
};


export type MutationResolveMagicLinkArgs = {
  code: Scalars['String']['input'];
};


export type MutationSendMagicLinkArgs = {
  email: Scalars['String']['input'];
};


export type MutationSendSigningDocumentsArgs = {
  onboardingRequestId: Scalars['UUID']['input'];
};


export type MutationUpdateContactArgs = {
  contact: ContactInput;
  id: Scalars['UUID']['input'];
};


export type MutationUpdateProfileArgs = {
  profile: ProfileInput;
};

export enum NoticePeriod {
  Extended_60 = 'EXTENDED_60',
  Standard_30 = 'STANDARD_30'
}

export type OnboardingContract = {
  __typename?: 'OnboardingContract';
  contractType: Scalars['String']['output'];
  country: Scalars['String']['output'];
  isCommission: Scalars['Boolean']['output'];
  noticePeriod: NoticePeriod;
  salary: Money;
  startDate: Scalars['NaiveDate']['output'];
};

export type OnboardingContractInput = {
  contractType: Scalars['String']['input'];
  country: Scalars['String']['input'];
  isCommission: Scalars['Boolean']['input'];
  noticePeriod: NoticePeriod;
  salary: MoneyInput;
  startDate: Scalars['NaiveDate']['input'];
};

export type OnboardingEmployeeProfile = {
  __typename?: 'OnboardingEmployeeProfile';
  email: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  gender: Gender;
  nationality: Scalars['String']['output'];
  phone?: Maybe<Phone>;
};

export type OnboardingEmployeeProfileInput = {
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  gender: Gender;
  nationality: Scalars['String']['input'];
  phone?: InputMaybe<PhoneInput>;
};

export type OnboardingLeave = {
  __typename?: 'OnboardingLeave';
  allowCarryForward: Scalars['Boolean']['output'];
  annualLeave: Scalars['Int']['output'];
  annualLeaveCarryForward: Scalars['Int']['output'];
  publicHoliday: Scalars['String']['output'];
};

export type OnboardingLeaveInput = {
  annualLeave: Scalars['Int']['input'];
  annualLeaveCarryForward: Scalars['Int']['input'];
  publicHoliday: Scalars['String']['input'];
};

export type OnboardingRequest = {
  __typename?: 'OnboardingRequest';
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  contract: OnboardingContract;
  createdAt: Scalars['DateTime']['output'];
  documentId?: Maybe<Scalars['UUID']['output']>;
  employee: OnboardingEmployeeProfile;
  hubberSigning?: Maybe<OnboardingRequestSigning>;
  id: Scalars['UUID']['output'];
  leave: OnboardingLeave;
  quotation?: Maybe<Quotation>;
  quotationId: Scalars['UUID']['output'];
  role: OnboardingRole;
  updatedAt: Scalars['DateTime']['output'];
};

export type OnboardingRequestInput = {
  contract: OnboardingContractInput;
  employee: OnboardingEmployeeProfileInput;
  leave: OnboardingLeaveInput;
  role: OnboardingRoleInput;
};

export type OnboardingRequestSigning = {
  __typename?: 'OnboardingRequestSigning';
  isSigned: Scalars['Boolean']['output'];
  signingUrl?: Maybe<Scalars['String']['output']>;
};

export type OnboardingRole = {
  __typename?: 'OnboardingRole';
  department: Scalars['String']['output'];
  jobTitle: Scalars['String']['output'];
  managerJobTitle?: Maybe<Scalars['String']['output']>;
  managerName?: Maybe<Scalars['String']['output']>;
};

export type OnboardingRoleInput = {
  department: Scalars['String']['input'];
  jobTitle: Scalars['String']['input'];
  managerJobTitle?: InputMaybe<Scalars['String']['input']>;
  managerName?: InputMaybe<Scalars['String']['input']>;
};

export type PaginatedExpenseClaim = {
  __typename?: 'PaginatedExpenseClaim';
  data: Array<ExpenseClaim>;
  pageNo: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedHubber = {
  __typename?: 'PaginatedHubber';
  data: Array<Hubber>;
  pageNo: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedInvoice = {
  __typename?: 'PaginatedInvoice';
  data: Array<Invoice>;
  pageNo: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedOnboardingRequest = {
  __typename?: 'PaginatedOnboardingRequest';
  data: Array<OnboardingRequest>;
  pageNo: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedPaymentLine = {
  __typename?: 'PaginatedPaymentLine';
  data: Array<PaymentLine>;
  pageNo: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedTimeOffRequest = {
  __typename?: 'PaginatedTimeOffRequest';
  data: Array<TimeOffRequest>;
  pageNo: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Payment = {
  __typename?: 'Payment';
  amount: Money;
  date: Scalars['NaiveDate']['output'];
  id: Scalars['UUID']['output'];
  isPdfReady: Scalars['Boolean']['output'];
  lines: Array<PaymentLine>;
  method: PaymentMethod;
};

export type PaymentLine = {
  __typename?: 'PaymentLine';
  amount: Money;
  date: Scalars['NaiveDate']['output'];
  id: Scalars['UUID']['output'];
  invoice: Invoice;
  payment: Payment;
};

export enum PaymentLineSortField {
  Date = 'DATE',
  Id = 'ID',
  PaymentNumber = 'PAYMENT_NUMBER'
}

export type PaymentLineSortInput = {
  field: PaymentLineSortField;
  order: SortOrder;
};

export enum PaymentMethod {
  BankTransfer = 'BANK_TRANSFER'
}

export type Permission = {
  __typename?: 'Permission';
  action: Action;
  resourceType: ResourceType;
};

export type Phone = {
  __typename?: 'Phone';
  countryCode: Scalars['String']['output'];
  extension?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
};

export type PhoneInput = {
  countryCode: Scalars['String']['input'];
  extension?: InputMaybe<Scalars['String']['input']>;
  number: Scalars['String']['input'];
};

export type PlatformPayment = {
  cancelReason?: Maybe<Scalars['String']['output']>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  invoices: Array<Invoice>;
  method: PlatformPaymentMethod;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  referenceId: Scalars['String']['output'];
  total: Money;
};

export type PlatformPaymentBankTransfer = PlatformPayment & {
  __typename?: 'PlatformPaymentBankTransfer';
  bankCode: Scalars['String']['output'];
  bankName: Scalars['String']['output'];
  beneficiaryName: Scalars['String']['output'];
  cancelReason?: Maybe<Scalars['String']['output']>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  invoices: Array<Invoice>;
  method: PlatformPaymentMethod;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  referenceId: Scalars['String']['output'];
  total: Money;
  virtualAccount: Scalars['String']['output'];
};

export enum PlatformPaymentMethod {
  BankTransfer = 'BANK_TRANSFER'
}

export type ProfileInput = {
  department?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<PhoneInput>;
};

export type PublicHoliday = {
  __typename?: 'PublicHoliday';
  countryCode: Scalars['String']['output'];
  data?: Maybe<Array<PublicHolidayData>>;
};


export type PublicHolidayDataArgs = {
  year: Scalars['Int']['input'];
};

export type PublicHolidayData = {
  __typename?: 'PublicHolidayData';
  date: Scalars['String']['output'];
  day: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  company: Company;
  contact: Contact;
  estimatedCost: CostStructure;
  expenseClaim: ExpenseClaim;
  expenseClaims: PaginatedExpenseClaim;
  hubber: Hubber;
  hubbers: PaginatedHubber;
  invoice: Invoice;
  invoiceByIds: Array<Invoice>;
  invoiceSummary: InvoiceSummary;
  invoices: PaginatedInvoice;
  onboardingRequest: OnboardingRequest;
  onboardingRequests: PaginatedOnboardingRequest;
  paymentLines: PaginatedPaymentLine;
  platformPayment: PlatformPayment;
  predefinedRoles: Array<Role>;
  timeoffRequest?: Maybe<TimeOffRequest>;
  timeoffRequests: PaginatedTimeOffRequest;
};


export type QueryContactArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryEstimatedCostArgs = {
  country: Scalars['String']['input'];
  currency?: InputMaybe<Scalars['String']['input']>;
  parameters: EstimatedCostParameter;
};


export type QueryExpenseClaimArgs = {
  expenseClaimId: Scalars['UUID']['input'];
};


export type QueryExpenseClaimsArgs = {
  expenseClaimStatus?: InputMaybe<Array<ExpenseClaimsStatusFilter>>;
  hub?: InputMaybe<Array<Scalars['String']['input']>>;
  hubberName?: InputMaybe<Scalars['String']['input']>;
  hubberStatus?: InputMaybe<Array<EmploymentStatus>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<Array<ExpenseClaimSortInput>>;
};


export type QueryHubberArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryHubbersArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  period?: InputMaybe<Month>;
  sorts?: InputMaybe<Array<HubberSortInput>>;
  status?: InputMaybe<Array<EmploymentStatus>>;
};


export type QueryInvoiceArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryInvoiceByIdsArgs = {
  ids: Array<Scalars['UUID']['input']>;
};


export type QueryInvoiceSummaryArgs = {
  overduePeriodDay?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInvoicesArgs = {
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<Array<InvoiceSortInput>>;
};


export type QueryOnboardingRequestArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryOnboardingRequestsArgs = {
  hubberName?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPaymentLinesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<Array<PaymentLineSortInput>>;
};


export type QueryPlatformPaymentArgs = {
  referenceId: Scalars['String']['input'];
};


export type QueryTimeoffRequestArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryTimeoffRequestsArgs = {
  hub?: InputMaybe<Array<Scalars['String']['input']>>;
  hubberName?: InputMaybe<Scalars['String']['input']>;
  hubberStatus?: InputMaybe<Array<EmploymentStatus>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  requestStatus?: InputMaybe<Array<TimeOffRequestStatus>>;
  sorts?: InputMaybe<Array<TimeOffRequestSortInput>>;
};

export type Quotation = {
  __typename?: 'Quotation';
  createdAt: Scalars['DateTime']['output'];
  isSigned: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export enum ResourceType {
  AttendanceLog = 'ATTENDANCE_LOG',
  CompanyDetails = 'COMPANY_DETAILS',
  ContactPersons = 'CONTACT_PERSONS',
  DirectDebit = 'DIRECT_DEBIT',
  ExpenseClaims = 'EXPENSE_CLAIMS',
  Hubbers = 'HUBBERS',
  Invoice = 'INVOICE',
  Login = 'LOGIN',
  OnboardingRequest = 'ONBOARDING_REQUEST',
  PlatformPayment = 'PLATFORM_PAYMENT',
  TimeOff = 'TIME_OFF'
}

export type Role = {
  __typename?: 'Role';
  id: Scalars['RoleId']['output'];
  name: Scalars['String']['output'];
  permissions: Array<Permission>;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type TimeOffBalance = {
  __typename?: 'TimeOffBalance';
  available?: Maybe<Scalars['Decimal']['output']>;
  balance?: Maybe<Scalars['Decimal']['output']>;
  category: TimeOffCategory;
  hubberId: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  used: Scalars['Decimal']['output'];
};


export type TimeOffBalanceAvailableArgs = {
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};


export type TimeOffBalanceBalanceArgs = {
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};


export type TimeOffBalanceUsedArgs = {
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export enum TimeOffCategory {
  AnnualLeave = 'ANNUAL_LEAVE',
  Others = 'OTHERS',
  SickLeave = 'SICK_LEAVE'
}

export type TimeOffRequest = {
  __typename?: 'TimeOffRequest';
  approval?: Maybe<TimeOffRequestApproval>;
  balance: TimeOffBalance;
  hubber: Hubber;
  hubberId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  noteToManager?: Maybe<Scalars['String']['output']>;
  requestStatus: TimeOffRequestStatus;
  requestedAt: Scalars['DateTime']['output'];
  timeoffCode: Scalars['String']['output'];
  timeoffDuration: Scalars['Decimal']['output'];
  timeoffEndDate: Scalars['NaiveDate']['output'];
  timeoffStartDate: Scalars['NaiveDate']['output'];
};

export type TimeOffRequestApproval = {
  __typename?: 'TimeOffRequestApproval';
  contact?: Maybe<Contact>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  remarks?: Maybe<Scalars['String']['output']>;
  status: TimeOffRequestStatus;
  timeoffRequestId: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum TimeOffRequestSortField {
  RequestedAt = 'REQUESTED_AT',
  TimeoffEndDate = 'TIMEOFF_END_DATE',
  TimeoffStartDate = 'TIMEOFF_START_DATE'
}

export type TimeOffRequestSortInput = {
  field: TimeOffRequestSortField;
  order: SortOrder;
};

export enum TimeOffRequestStatus {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type Token = {
  __typename?: 'Token';
  accessToken: Scalars['String']['output'];
  tokenType: Scalars['String']['output'];
};

export type CreateInvoiceDisputeMutationVariables = Exact<{
  invoiceId: Scalars['UUID']['input'];
  reason: Scalars['String']['input'];
}>;


export type CreateInvoiceDisputeMutation = { __typename?: 'Mutation', createInvoiceDispute: { __typename?: 'InvoiceDispute', id: any, invoiceId: any, reason: string } };

export type CreatePlatformPaymentMutationVariables = Exact<{
  paymentMethod: PlatformPaymentMethod;
  invoiceIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;


export type CreatePlatformPaymentMutation = { __typename?: 'Mutation', createPlatformPayment: { __typename?: 'PlatformPaymentBankTransfer', beneficiaryName: string, bankName: string, bankCode: string, virtualAccount: string, id: any, referenceId: string, method: PlatformPaymentMethod, createdAt: any, paidAt?: any | null, total: { __typename?: 'Money', currency: string, amount: any }, invoices: Array<{ __typename?: 'Invoice', referenceNumber: string }> } };

export type CreateUserMutationVariables = Exact<{
  contact: ContactInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', addContact?: { __typename?: 'Contact', id: any, name: string, email?: string | null, phone?: string | null, department?: string | null, jobTitle?: string | null, phoneNumber?: { __typename?: 'Phone', countryCode: string, number: string, extension?: string | null } | null } | null };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteContact: boolean };

export type DisconnectDirectDebitMutationVariables = Exact<{
  param: DisconnectDirectDebitParam;
}>;


export type DisconnectDirectDebitMutation = { __typename?: 'Mutation', disconnectDirectDebit: { __typename?: 'DirectDebitCancelation', id: any, cancelationUrl?: string | null } };

export type ExportAttendanceSummaryMutationVariables = Exact<{
  year: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
}>;


export type ExportAttendanceSummaryMutation = { __typename?: 'Mutation', exportAttendanceSummary: { __typename?: 'Export', fileUrl: string } };

export type ExportTalentAttendanceMutationVariables = Exact<{
  hubberId: Scalars['UUID']['input'];
  period: Month;
  status?: InputMaybe<Array<AttendanceStatus> | AttendanceStatus>;
}>;


export type ExportTalentAttendanceMutation = { __typename?: 'Mutation', exportHubberAttendance: { __typename?: 'Export', fileUrl: string } };

export type ExportTimeoffBalancesMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
}>;


export type ExportTimeoffBalancesMutation = { __typename?: 'Mutation', exportTimeoffBalances: { __typename?: 'Export', fileUrl: string } };

export type GetAllHubbersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<EmploymentStatus> | EmploymentStatus>;
}>;


export type GetAllHubbersQuery = { __typename?: 'Query', hubbers: { __typename?: 'PaginatedHubber', pageNo: number, pageSize: number, total: number, data: Array<{ __typename?: 'Hubber', id: any, fullName: string, hub: string, jobTitle: string, joinDate: any, contractEndDate?: any | null, resignDate?: any | null, employmentType: EmploymentType, contractType?: string | null, status: EmploymentStatus, email?: string | null, phoneNumber?: string | null }> } };

export type GetAttendanceLogQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<Array<HubberSortInput> | HubberSortInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<EmploymentStatus> | EmploymentStatus>;
  period: Month;
}>;


export type GetAttendanceLogQuery = { __typename?: 'Query', hubbers: { __typename?: 'PaginatedHubber', pageNo: number, pageSize: number, total: number, data: Array<{ __typename?: 'Hubber', id: any, fullName: string, joinDate: any, contractEndDate?: any | null, resignDate?: any | null, hub: string, jobTitle: string, status: EmploymentStatus, email?: string | null, phoneNumber?: string | null, attendance: { __typename?: 'AttendanceSummary', scheduledHours: any, actualHours: any, data: Array<{ __typename?: 'Attendance', date: any, clockIn?: any | null, clockOut?: any | null, scheduledClockIn?: any | null, scheduledClockOut?: any | null, status: AttendanceStatus }> } }> }, company: { __typename?: 'Company', hubberHubs: Array<string> } };

export type GetCompanyProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyProfileQuery = { __typename?: 'Query', company: { __typename?: 'Company', updatedAt: any, name: string, registeredName?: string | null, billingName?: string | null, billingEmail?: string | null, registeredAddress: { __typename?: 'Address', street?: string | null }, operatingAddress: { __typename?: 'Address', street?: string | null }, billingAddress: { __typename?: 'Address', street?: string | null }, accountManager?: { __typename?: 'AccountManager', id: any, email: string, name: string } | null } };

export type GetDirectDebitAuthorisationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDirectDebitAuthorisationQuery = { __typename?: 'Query', company: { __typename?: 'Company', id: any, directDebit?: { __typename?: 'DirectDebitAuthorization', bankSwiftBicCode: string, isActive: boolean, activatedAt?: any | null, nextCollectionDate?: any | null, authorizationUrl: string, status: DirectDebitStatus, failureReason?: string | null } | null } };

export type GetEstimatedCostQueryVariables = Exact<{
  country: Scalars['String']['input'];
  currency?: InputMaybe<Scalars['String']['input']>;
  parameters: EstimatedCostParameter;
}>;


export type GetEstimatedCostQuery = { __typename?: 'Query', estimatedCost: { __typename?: 'CostStructure', labelKey: string, descriptionKey: string, total: { __typename?: 'Money', inCurrencies: Array<{ __typename?: 'Money', currency: string, amount: any }> }, children: Array<{ __typename?: 'CostStructure', labelKey: string, descriptionKey: string, total: { __typename?: 'Money', inCurrencies: Array<{ __typename?: 'Money', currency: string, amount: any }> }, children: Array<{ __typename?: 'CostStructure', labelKey: string, descriptionKey: string, total: { __typename?: 'Money', inCurrencies: Array<{ __typename?: 'Money', currency: string, amount: any }> }, children: Array<{ __typename?: 'CostStructure', labelKey: string, descriptionKey: string, total: { __typename?: 'Money', inCurrencies: Array<{ __typename?: 'Money', currency: string, amount: any }> } }> }> }> } };

export type GetInvoiceByIdsQueryVariables = Exact<{
  ids: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;


export type GetInvoiceByIdsQuery = { __typename?: 'Query', invoiceByIds: Array<{ __typename?: 'Invoice', id: any, referenceNumber: string, isPdfReady: boolean, unpaidAmount: { __typename?: 'Money', currency: string, amount: any }, creditNoteLines: Array<{ __typename?: 'CreditNoteLine', amount: { __typename?: 'Money', currency: string, amount: any } }>, platformPayment?: { __typename?: 'PlatformPaymentBankTransfer', id: any, referenceId: string, method: PlatformPaymentMethod, paidAt?: any | null, createdAt: any, invoices: Array<{ __typename?: 'Invoice', id: any }> } | null }> };

export type GetInvoicePdfMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type GetInvoicePdfMutation = { __typename?: 'Mutation', invoicePdf: { __typename?: 'File', url: string } };

export type GetInvoiceInvoiceSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInvoiceInvoiceSummaryQuery = { __typename?: 'Query', invoiceSummary: { __typename?: 'InvoiceSummary', open: number, outstanding: number, overdue: number, totalUnpaid: Array<{ __typename?: 'Money', currency: string, amount: any }> } };

export type GetInvoicesQueryVariables = Exact<{
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<Array<InvoiceSortInput> | InvoiceSortInput>;
  paymentLineSortInput?: InputMaybe<Array<PaymentLineSortInput> | PaymentLineSortInput>;
}>;


export type GetInvoicesQuery = { __typename?: 'Query', invoices: { __typename?: 'PaginatedInvoice', pageNo: number, pageSize: number, total: number, data: Array<{ __typename?: 'Invoice', id: any, issueDate: any, dueDate?: any | null, referenceNumber: string, isPdfReady: boolean, closedDate?: any | null, paidAmount: { __typename?: 'Money', currency: string, amount: any }, amount: { __typename?: 'Money', currency: string, amount: any }, paymentLines: Array<{ __typename?: 'PaymentLine', id: any, date: any, amount: { __typename?: 'Money', currency: string, amount: any }, payment: { __typename?: 'Payment', id: any, date: any, amount: { __typename?: 'Money', currency: string, amount: any } } }>, dispute?: { __typename?: 'InvoiceDispute', id: any, invoiceId: any, reason: string, createdAt: any, createdBy: { __typename?: 'InvoiceDisputeRequester', id: any, name: string } } | null, platformPayment?: { __typename?: 'PlatformPaymentBankTransfer', beneficiaryName: string, bankName: string, bankCode: string, virtualAccount: string, id: any, referenceId: string, method: PlatformPaymentMethod, createdAt: any, paidAt?: any | null, invoices: Array<{ __typename?: 'Invoice', id: any, referenceNumber: string }>, total: { __typename?: 'Money', currency: string, amount: any } } | null }> } };

export type GetLastMonthTotalInvoiceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLastMonthTotalInvoiceQuery = { __typename?: 'Query', invoiceSummary: { __typename?: 'InvoiceSummary', lastMonthTotal: Array<{ __typename?: 'Money', currency: string, amount: any }> } };

export type GetPaymentCompletedQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  sorts?: InputMaybe<Array<PaymentLineSortInput> | PaymentLineSortInput>;
}>;


export type GetPaymentCompletedQuery = { __typename?: 'Query', paymentLines: { __typename?: 'PaginatedPaymentLine', pageNo: number, pageSize: number, total: number, data: Array<{ __typename?: 'PaymentLine', payment: { __typename?: 'Payment', id: any, method: PaymentMethod, isPdfReady: boolean, date: any }, invoice: { __typename?: 'Invoice', id: any, referenceNumber: string, closedDate?: any | null, amount: { __typename?: 'Money', currency: string, amount: any }, paidAmount: { __typename?: 'Money', currency: string, amount: any }, creditNoteLines: Array<{ __typename?: 'CreditNoteLine', amount: { __typename?: 'Money', currency: string, amount: any } }> } }> } };

export type GetPlatformPaymentQueryVariables = Exact<{
  referenceId: Scalars['String']['input'];
}>;


export type GetPlatformPaymentQuery = { __typename?: 'Query', platformPayment: { __typename?: 'PlatformPaymentBankTransfer', beneficiaryName: string, bankName: string, bankCode: string, virtualAccount: string, id: any, referenceId: string, method: PlatformPaymentMethod, createdAt: any, paidAt?: any | null, total: { __typename?: 'Money', currency: string, amount: any }, invoices: Array<{ __typename?: 'Invoice', referenceNumber: string }> } };

export type GetReceiptPdfMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type GetReceiptPdfMutation = { __typename?: 'Mutation', paymentPdf: { __typename?: 'File', url: string } };

export type GetTalentAttendanceLogsQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
  period: Month;
  sorts?: InputMaybe<Array<AttendanceSortInput> | AttendanceSortInput>;
  status?: InputMaybe<Array<AttendanceStatus> | AttendanceStatus>;
}>;


export type GetTalentAttendanceLogsQuery = { __typename?: 'Query', hubber: { __typename?: 'Hubber', id: any, fullName: string, hub: string, status: EmploymentStatus, attendance: { __typename?: 'AttendanceSummary', data: Array<{ __typename?: 'Attendance', date: any, clockIn?: any | null, clockOut?: any | null, status: AttendanceStatus }> } } };

export type TimeoffRequestsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<EmploymentStatus> | EmploymentStatus>;
  year: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
}>;


export type TimeoffRequestsQuery = { __typename?: 'Query', hubbers: { __typename?: 'PaginatedHubber', pageNo: number, pageSize: number, total: number, data: Array<{ __typename?: 'Hubber', id: any, fullName: string, status: EmploymentStatus, joinDate: any, contractEndDate?: any | null, resignDate?: any | null, timeoffBalance: Array<{ __typename?: 'TimeOffBalance', hubberId: any, name: string, category: TimeOffCategory, balance?: any | null, used: any, available?: any | null }>, publicHoliday?: { __typename?: 'PublicHoliday', countryCode: string, data?: Array<{ __typename?: 'PublicHolidayData', date: string, day: string, name: string }> | null } | null }> } };

export type GetUnassignedUsersCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnassignedUsersCountQuery = { __typename?: 'Query', company: { __typename?: 'Company', no_role_count: number } };

export type GetUserQueryVariables = Exact<{
  userId: Scalars['UUID']['input'];
}>;


export type GetUserQuery = { __typename?: 'Query', company: { __typename?: 'Company', name: string, billingAddress: { __typename?: 'Address', country?: string | null } }, contact: { __typename?: 'Contact', id: any, name: string, email?: string | null, phone?: string | null, department?: string | null, jobTitle?: string | null, phoneNumber?: { __typename?: 'Phone', countryCode: string, number: string, extension?: string | null } | null, roles: Array<{ __typename?: 'Role', id: any }> } };

export type GetUserRolesQueryVariables = Exact<{
  userId: Scalars['UUID']['input'];
}>;


export type GetUserRolesQuery = { __typename?: 'Query', contact: { __typename?: 'Contact', id: any, roles: Array<{ __typename?: 'Role', id: any }> } };

export type GetUsersCountPerRoleQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersCountPerRoleQuery = { __typename?: 'Query', company: { __typename?: 'Company', all_count: number, no_role_count: number, admin_count: number, people_manager_count: number, payer_count: number, viewer_count: number } };

export type GetUsersPerRoleQueryVariables = Exact<{
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['RoleId']['input']>> | InputMaybe<Scalars['RoleId']['input']>>;
}>;


export type GetUsersPerRoleQuery = { __typename?: 'Query', company: { __typename?: 'Company', contacts: Array<{ __typename?: 'Contact', id: any, name: string, email?: string | null, phone?: string | null, department?: string | null, jobTitle?: string | null, updatedAt: any, phoneNumber?: { __typename?: 'Phone', countryCode: string, number: string, extension?: string | null } | null }> } };

export type InitializeDirectDebitMutationVariables = Exact<{
  param: InitializeDirectDebitParam;
}>;


export type InitializeDirectDebitMutation = { __typename?: 'Mutation', initializeDirectDebit: { __typename?: 'DirectDebitAuthorization', id: any, bankSwiftBicCode: string, authorizationUrl: string, isActive: boolean, nextCollectionDate?: any | null } };

export type ResolveMagicLinkMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type ResolveMagicLinkMutation = { __typename?: 'Mutation', resolveMagicLink: { __typename?: 'Token', tokenType: string, accessToken: string } };

export type SendMagicLinkMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type SendMagicLinkMutation = { __typename?: 'Mutation', sendMagicLink: boolean };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  contact: ContactInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateContact?: { __typename?: 'Contact', id: any, name: string, email?: string | null, phone?: string | null, department?: string | null, jobTitle?: string | null, phoneNumber?: { __typename?: 'Phone', countryCode: string, number: string, extension?: string | null } | null } | null };

export type UpdateUserProfileMutationVariables = Exact<{
  userProfileInput: ProfileInput;
}>;


export type UpdateUserProfileMutation = { __typename?: 'Mutation', updateProfile: { __typename?: 'Contact', id: any, name: string, email?: string | null, department?: string | null, jobTitle?: string | null, phoneNumber?: { __typename?: 'Phone', countryCode: string, number: string, extension?: string | null } | null, roles: Array<{ __typename?: 'Role', id: any, name: string }> } };

export type GetCompanyHubberHubsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyHubberHubsQuery = { __typename?: 'Query', company: { __typename?: 'Company', hubberHubs: Array<string> } };

export type ExportExpenseClaimMutationVariables = Exact<{
  hubberName?: InputMaybe<Scalars['String']['input']>;
  hub?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  claimStatus?: InputMaybe<Array<ExpenseClaimsStatusFilter> | ExpenseClaimsStatusFilter>;
  hubberStatus?: InputMaybe<Array<EmploymentStatus> | EmploymentStatus>;
  currencyInput: Scalars['String']['input'];
}>;


export type ExportExpenseClaimMutation = { __typename?: 'Mutation', exportExpenseClaim: { __typename?: 'Export', fileUrl: string } };

export type GetExpenseClaimsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sorts?: InputMaybe<Array<ExpenseClaimSortInput> | ExpenseClaimSortInput>;
  hubberName?: InputMaybe<Scalars['String']['input']>;
  hubberStatus?: InputMaybe<Array<EmploymentStatus> | EmploymentStatus>;
  expenseClaimStatus?: InputMaybe<Array<ExpenseClaimsStatusFilter> | ExpenseClaimsStatusFilter>;
  hub?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  baseCurrency: Scalars['String']['input'];
}>;


export type GetExpenseClaimsQuery = { __typename?: 'Query', expenseClaims: { __typename?: 'PaginatedExpenseClaim', pageNo: number, pageSize: number, total: number, data: Array<{ __typename?: 'ExpenseClaim', id: any, description?: string | null, notesToManager?: string | null, category: string, createdAt: any, updatedAt: any, hubber: { __typename?: 'Hubber', id: any, fullName: string, hub: string, status: EmploymentStatus }, amount: { __typename?: 'Money', currency: string, amount: any }, baseAmount: { __typename?: 'Money', currency: string, amount: any }, attachments: Array<{ __typename?: 'ExpenseClaimAttachment', id: any, fileName: string }>, approval?: { __typename?: 'ExpenseClaimApproval', id: any, status: ExpenseClaimsStatus, remarks?: string | null, createdAt: any, updatedAt: any, approver: { __typename?: 'Contact', id: any, name: string } } | null }> } };

export type ApproveExpenseClaimsMutationVariables = Exact<{
  expenseClaimId: Scalars['UUID']['input'];
  remarks?: InputMaybe<Scalars['String']['input']>;
  baseCurrency: Scalars['String']['input'];
}>;


export type ApproveExpenseClaimsMutation = { __typename?: 'Mutation', approveExpenseClaims: { __typename?: 'ExpenseClaim', id: any, description?: string | null, notesToManager?: string | null, category: string, createdAt: any, updatedAt: any, hubber: { __typename?: 'Hubber', id: any, fullName: string, hub: string, status: EmploymentStatus }, amount: { __typename?: 'Money', currency: string, amount: any }, baseAmount: { __typename?: 'Money', currency: string, amount: any }, attachments: Array<{ __typename?: 'ExpenseClaimAttachment', id: any, fileName: string }>, approval?: { __typename?: 'ExpenseClaimApproval', id: any, status: ExpenseClaimsStatus, remarks?: string | null, createdAt: any, updatedAt: any, approver: { __typename?: 'Contact', id: any, name: string } } | null } };

export type RejectExpenseClaimsMutationVariables = Exact<{
  expenseClaimId: Scalars['UUID']['input'];
  remarks?: InputMaybe<Scalars['String']['input']>;
  baseCurrency: Scalars['String']['input'];
}>;


export type RejectExpenseClaimsMutation = { __typename?: 'Mutation', rejectExpenseClaims: { __typename?: 'ExpenseClaim', id: any, description?: string | null, notesToManager?: string | null, category: string, createdAt: any, updatedAt: any, hubber: { __typename?: 'Hubber', id: any, fullName: string, hub: string, status: EmploymentStatus }, amount: { __typename?: 'Money', currency: string, amount: any }, baseAmount: { __typename?: 'Money', currency: string, amount: any }, attachments: Array<{ __typename?: 'ExpenseClaimAttachment', id: any, fileName: string }>, approval?: { __typename?: 'ExpenseClaimApproval', id: any, status: ExpenseClaimsStatus, remarks?: string | null, createdAt: any, updatedAt: any, approver: { __typename?: 'Contact', id: any, name: string } } | null } };

export type ExpenseClaimAttachmentUrlMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type ExpenseClaimAttachmentUrlMutation = { __typename?: 'Mutation', expenseClaimAttachmentUrl: { __typename?: 'File', url: string } };

export type ExpensesTableExpenseClaimFragment = { __typename?: 'ExpenseClaim', id: any, description?: string | null, notesToManager?: string | null, category: string, createdAt: any, updatedAt: any, hubber: { __typename?: 'Hubber', id: any, fullName: string, hub: string, status: EmploymentStatus }, amount: { __typename?: 'Money', currency: string, amount: any }, baseAmount: { __typename?: 'Money', currency: string, amount: any }, attachments: Array<{ __typename?: 'ExpenseClaimAttachment', id: any, fileName: string }>, approval?: { __typename?: 'ExpenseClaimApproval', id: any, status: ExpenseClaimsStatus, remarks?: string | null, createdAt: any, updatedAt: any, approver: { __typename?: 'Contact', id: any, name: string } } | null };

export type ExportTimeoffRequestsMutationVariables = Exact<{
  hubberName?: InputMaybe<Scalars['String']['input']>;
  hubberStatus?: InputMaybe<Array<EmploymentStatus> | EmploymentStatus>;
  requestStatus?: InputMaybe<Array<TimeOffRequestStatus> | TimeOffRequestStatus>;
  hub?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type ExportTimeoffRequestsMutation = { __typename?: 'Mutation', exportTimeoffRequests: { __typename?: 'Export', fileUrl: string } };

export type GetTimeOffRequestsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  hubberName?: InputMaybe<Scalars['String']['input']>;
  requestStatus?: InputMaybe<Array<TimeOffRequestStatus> | TimeOffRequestStatus>;
  sorts?: InputMaybe<Array<TimeOffRequestSortInput> | TimeOffRequestSortInput>;
  hubberStatus?: InputMaybe<Array<EmploymentStatus> | EmploymentStatus>;
  hub?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  year: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
}>;


export type GetTimeOffRequestsQuery = { __typename?: 'Query', timeoffRequests: { __typename?: 'PaginatedTimeOffRequest', pageNo: number, pageSize: number, total: number, data: Array<{ __typename?: 'TimeOffRequest', id: any, noteToManager?: string | null, requestStatus: TimeOffRequestStatus, requestedAt: any, timeoffStartDate: any, timeoffEndDate: any, timeoffDuration: any, hubber: { __typename?: 'Hubber', id: any, fullName: string, hub: string, status: EmploymentStatus }, approval?: { __typename?: 'TimeOffRequestApproval', id: any, remarks?: string | null, createdAt: any, updatedAt: any, contact?: { __typename?: 'Contact', id: any, name: string } | null } | null, balance: { __typename?: 'TimeOffBalance', hubberId: any, name: string, category: TimeOffCategory, balance?: any | null, used: any, available?: any | null } }> } };

export type ApproveTimeOffRequestMutationVariables = Exact<{
  timeOffId: Scalars['UUID']['input'];
  year: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
}>;


export type ApproveTimeOffRequestMutation = { __typename?: 'Mutation', approveTimeOffRequest: { __typename?: 'TimeOffRequest', id: any, noteToManager?: string | null, requestStatus: TimeOffRequestStatus, requestedAt: any, timeoffStartDate: any, timeoffEndDate: any, timeoffDuration: any, hubber: { __typename?: 'Hubber', id: any, fullName: string, hub: string, status: EmploymentStatus }, approval?: { __typename?: 'TimeOffRequestApproval', id: any, remarks?: string | null, createdAt: any, updatedAt: any, contact?: { __typename?: 'Contact', id: any, name: string } | null } | null, balance: { __typename?: 'TimeOffBalance', hubberId: any, name: string, category: TimeOffCategory, balance?: any | null, used: any, available?: any | null } } };

export type RejectTimeOffRequestMutationVariables = Exact<{
  timeOffId: Scalars['UUID']['input'];
  remark?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
}>;


export type RejectTimeOffRequestMutation = { __typename?: 'Mutation', rejectTimeOffRequest: { __typename?: 'TimeOffRequest', id: any, noteToManager?: string | null, requestStatus: TimeOffRequestStatus, requestedAt: any, timeoffStartDate: any, timeoffEndDate: any, timeoffDuration: any, hubber: { __typename?: 'Hubber', id: any, fullName: string, hub: string, status: EmploymentStatus }, approval?: { __typename?: 'TimeOffRequestApproval', id: any, remarks?: string | null, createdAt: any, updatedAt: any, contact?: { __typename?: 'Contact', id: any, name: string } | null } | null, balance: { __typename?: 'TimeOffBalance', hubberId: any, name: string, category: TimeOffCategory, balance?: any | null, used: any, available?: any | null } } };

export type GetTimeOffRequestQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
  year: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
}>;


export type GetTimeOffRequestQuery = { __typename?: 'Query', timeoffRequest?: { __typename?: 'TimeOffRequest', id: any, noteToManager?: string | null, requestStatus: TimeOffRequestStatus, requestedAt: any, timeoffStartDate: any, timeoffEndDate: any, timeoffDuration: any, hubber: { __typename?: 'Hubber', id: any, fullName: string, hub: string, status: EmploymentStatus }, approval?: { __typename?: 'TimeOffRequestApproval', id: any, remarks?: string | null, createdAt: any, updatedAt: any, contact?: { __typename?: 'Contact', id: any, name: string } | null } | null, balance: { __typename?: 'TimeOffBalance', hubberId: any, name: string, category: TimeOffCategory, balance?: any | null, used: any, available?: any | null } } | null };

export type TimeOffRequestsTableTimeOffRequestFragment = { __typename?: 'TimeOffRequest', id: any, noteToManager?: string | null, requestStatus: TimeOffRequestStatus, requestedAt: any, timeoffStartDate: any, timeoffEndDate: any, timeoffDuration: any, hubber: { __typename?: 'Hubber', id: any, fullName: string, hub: string, status: EmploymentStatus }, approval?: { __typename?: 'TimeOffRequestApproval', id: any, remarks?: string | null, createdAt: any, updatedAt: any, contact?: { __typename?: 'Contact', id: any, name: string } | null } | null, balance: { __typename?: 'TimeOffBalance', hubberId: any, name: string, category: TimeOffCategory, balance?: any | null, used: any, available?: any | null } };

export const ExpensesTableExpenseClaimFragmentDoc = `
    fragment ExpensesTableExpenseClaim on ExpenseClaim {
  id
  hubber {
    id
    fullName
    hub
    status
  }
  amount {
    currency
    amount
  }
  baseAmount: amount(currency: $baseCurrency) {
    currency
    amount
  }
  description
  notesToManager
  attachments {
    id
    fileName
  }
  category
  approval {
    id
    approver {
      id
      name
    }
    status
    remarks
    createdAt
    updatedAt
  }
  createdAt
  updatedAt
}
    `;
export const TimeOffRequestsTableTimeOffRequestFragmentDoc = `
    fragment TimeOffRequestsTableTimeOffRequest on TimeOffRequest {
  id
  noteToManager
  requestStatus
  requestedAt
  timeoffStartDate
  timeoffEndDate
  timeoffDuration
  hubber {
    id
    fullName
    hub
    status
  }
  approval {
    id
    remarks
    contact {
      id
      name
    }
    createdAt
    updatedAt
  }
  balance {
    hubberId
    name
    category
    balance(year: $year, month: $month)
    used(year: $year, month: $month)
    available(year: $year, month: $month)
  }
}
    `;
export const CreateInvoiceDisputeDocument = `
    mutation CreateInvoiceDispute($invoiceId: UUID!, $reason: String!) {
  createInvoiceDispute(invoiceId: $invoiceId, reason: $reason) {
    id
    invoiceId
    reason
  }
}
    `;
export const useCreateInvoiceDisputeMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateInvoiceDisputeMutation, TError, CreateInvoiceDisputeMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreateInvoiceDisputeMutation, TError, CreateInvoiceDisputeMutationVariables, TContext>(
      ['CreateInvoiceDispute'],
      (variables?: CreateInvoiceDisputeMutationVariables) => fetcher<CreateInvoiceDisputeMutation, CreateInvoiceDisputeMutationVariables>(client, CreateInvoiceDisputeDocument, variables, headers)(),
      options
    );
export const CreatePlatformPaymentDocument = `
    mutation CreatePlatformPayment($paymentMethod: PlatformPaymentMethod!, $invoiceIds: [UUID!]!) {
  createPlatformPayment(method: $paymentMethod, invoiceIds: $invoiceIds) {
    id
    referenceId
    method
    paidAt
    createdAt
    ... on PlatformPaymentBankTransfer {
      beneficiaryName
      bankName
      bankCode
      virtualAccount
      id
      referenceId
      method
      createdAt
      paidAt
    }
    total {
      currency
      amount
    }
    invoices {
      referenceNumber
    }
  }
}
    `;
export const useCreatePlatformPaymentMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreatePlatformPaymentMutation, TError, CreatePlatformPaymentMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreatePlatformPaymentMutation, TError, CreatePlatformPaymentMutationVariables, TContext>(
      ['CreatePlatformPayment'],
      (variables?: CreatePlatformPaymentMutationVariables) => fetcher<CreatePlatformPaymentMutation, CreatePlatformPaymentMutationVariables>(client, CreatePlatformPaymentDocument, variables, headers)(),
      options
    );
export const CreateUserDocument = `
    mutation CreateUser($contact: ContactInput!) {
  addContact(contact: $contact) {
    id
    name
    email
    phone
    phoneNumber {
      countryCode
      number
      extension
    }
    department
    jobTitle
  }
}
    `;
export const useCreateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateUserMutation, TError, CreateUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreateUserMutation, TError, CreateUserMutationVariables, TContext>(
      ['CreateUser'],
      (variables?: CreateUserMutationVariables) => fetcher<CreateUserMutation, CreateUserMutationVariables>(client, CreateUserDocument, variables, headers)(),
      options
    );
export const DeleteUserDocument = `
    mutation DeleteUser($id: UUID!) {
  deleteContact(id: $id)
}
    `;
export const useDeleteUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteUserMutation, TError, DeleteUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeleteUserMutation, TError, DeleteUserMutationVariables, TContext>(
      ['DeleteUser'],
      (variables?: DeleteUserMutationVariables) => fetcher<DeleteUserMutation, DeleteUserMutationVariables>(client, DeleteUserDocument, variables, headers)(),
      options
    );
export const DisconnectDirectDebitDocument = `
    mutation DisconnectDirectDebit($param: DisconnectDirectDebitParam!) {
  disconnectDirectDebit(param: $param) {
    id
    cancelationUrl
  }
}
    `;
export const useDisconnectDirectDebitMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DisconnectDirectDebitMutation, TError, DisconnectDirectDebitMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DisconnectDirectDebitMutation, TError, DisconnectDirectDebitMutationVariables, TContext>(
      ['DisconnectDirectDebit'],
      (variables?: DisconnectDirectDebitMutationVariables) => fetcher<DisconnectDirectDebitMutation, DisconnectDirectDebitMutationVariables>(client, DisconnectDirectDebitDocument, variables, headers)(),
      options
    );
export const ExportAttendanceSummaryDocument = `
    mutation ExportAttendanceSummary($year: Int!, $month: Int!) {
  exportAttendanceSummary(period: {year: $year, month: $month}) {
    fileUrl
  }
}
    `;
export const useExportAttendanceSummaryMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ExportAttendanceSummaryMutation, TError, ExportAttendanceSummaryMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ExportAttendanceSummaryMutation, TError, ExportAttendanceSummaryMutationVariables, TContext>(
      ['ExportAttendanceSummary'],
      (variables?: ExportAttendanceSummaryMutationVariables) => fetcher<ExportAttendanceSummaryMutation, ExportAttendanceSummaryMutationVariables>(client, ExportAttendanceSummaryDocument, variables, headers)(),
      options
    );
export const ExportTalentAttendanceDocument = `
    mutation ExportTalentAttendance($hubberId: UUID!, $period: Month!, $status: [AttendanceStatus!]) {
  exportHubberAttendance(hubberId: $hubberId, period: $period, status: $status) {
    fileUrl
  }
}
    `;
export const useExportTalentAttendanceMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ExportTalentAttendanceMutation, TError, ExportTalentAttendanceMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ExportTalentAttendanceMutation, TError, ExportTalentAttendanceMutationVariables, TContext>(
      ['ExportTalentAttendance'],
      (variables?: ExportTalentAttendanceMutationVariables) => fetcher<ExportTalentAttendanceMutation, ExportTalentAttendanceMutationVariables>(client, ExportTalentAttendanceDocument, variables, headers)(),
      options
    );
export const ExportTimeoffBalancesDocument = `
    mutation ExportTimeoffBalances($name: String, $year: Int!, $month: Int!) {
  exportTimeoffBalances(name: $name, year: $year, month: $month) {
    fileUrl
  }
}
    `;
export const useExportTimeoffBalancesMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ExportTimeoffBalancesMutation, TError, ExportTimeoffBalancesMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ExportTimeoffBalancesMutation, TError, ExportTimeoffBalancesMutationVariables, TContext>(
      ['ExportTimeoffBalances'],
      (variables?: ExportTimeoffBalancesMutationVariables) => fetcher<ExportTimeoffBalancesMutation, ExportTimeoffBalancesMutationVariables>(client, ExportTimeoffBalancesDocument, variables, headers)(),
      options
    );
export const GetAllHubbersDocument = `
    query GetAllHubbers($page: Int, $pageSize: Int, $name: String, $status: [EmploymentStatus!]) {
  hubbers(
    page: $page
    pageSize: $pageSize
    name: $name
    status: $status
    sorts: {field: FULL_NAME, order: ASC}
  ) {
    data {
      id
      fullName
      hub
      jobTitle
      joinDate
      contractEndDate
      resignDate
      employmentType
      contractType
      status
      email
      phoneNumber
    }
    pageNo
    pageSize
    total
  }
}
    `;
export const useGetAllHubbersQuery = <
      TData = GetAllHubbersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetAllHubbersQueryVariables,
      options?: UseQueryOptions<GetAllHubbersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetAllHubbersQuery, TError, TData>(
      variables === undefined ? ['GetAllHubbers'] : ['GetAllHubbers', variables],
      fetcher<GetAllHubbersQuery, GetAllHubbersQueryVariables>(client, GetAllHubbersDocument, variables, headers),
      options
    );
export const GetAttendanceLogDocument = `
    query GetAttendanceLog($page: Int, $pageSize: Int, $sorts: [HubberSortInput!], $name: String, $status: [EmploymentStatus!], $period: Month!) {
  hubbers(
    page: $page
    pageSize: $pageSize
    sorts: $sorts
    name: $name
    status: $status
    period: $period
  ) {
    pageNo
    pageSize
    total
    data {
      id
      fullName
      joinDate
      contractEndDate
      resignDate
      hub
      jobTitle
      status
      email
      phoneNumber
      attendance(period: $period) {
        scheduledHours
        actualHours
        data {
          date
          clockIn
          clockOut
          scheduledClockIn
          scheduledClockOut
          status
        }
      }
    }
  }
  company {
    hubberHubs
  }
}
    `;
export const useGetAttendanceLogQuery = <
      TData = GetAttendanceLogQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetAttendanceLogQueryVariables,
      options?: UseQueryOptions<GetAttendanceLogQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetAttendanceLogQuery, TError, TData>(
      ['GetAttendanceLog', variables],
      fetcher<GetAttendanceLogQuery, GetAttendanceLogQueryVariables>(client, GetAttendanceLogDocument, variables, headers),
      options
    );
export const GetCompanyProfileDocument = `
    query GetCompanyProfile {
  company {
    updatedAt
    name
    registeredName
    registeredAddress {
      street
    }
    operatingAddress {
      street
    }
    billingName
    billingEmail
    billingAddress {
      street
    }
    accountManager {
      id
      email
      name
    }
  }
}
    `;
export const useGetCompanyProfileQuery = <
      TData = GetCompanyProfileQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetCompanyProfileQueryVariables,
      options?: UseQueryOptions<GetCompanyProfileQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetCompanyProfileQuery, TError, TData>(
      variables === undefined ? ['GetCompanyProfile'] : ['GetCompanyProfile', variables],
      fetcher<GetCompanyProfileQuery, GetCompanyProfileQueryVariables>(client, GetCompanyProfileDocument, variables, headers),
      options
    );
export const GetDirectDebitAuthorisationDocument = `
    query GetDirectDebitAuthorisation {
  company {
    id
    directDebit {
      bankSwiftBicCode
      isActive
      activatedAt
      nextCollectionDate
      authorizationUrl
      status
      failureReason
    }
  }
}
    `;
export const useGetDirectDebitAuthorisationQuery = <
      TData = GetDirectDebitAuthorisationQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetDirectDebitAuthorisationQueryVariables,
      options?: UseQueryOptions<GetDirectDebitAuthorisationQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetDirectDebitAuthorisationQuery, TError, TData>(
      variables === undefined ? ['GetDirectDebitAuthorisation'] : ['GetDirectDebitAuthorisation', variables],
      fetcher<GetDirectDebitAuthorisationQuery, GetDirectDebitAuthorisationQueryVariables>(client, GetDirectDebitAuthorisationDocument, variables, headers),
      options
    );
export const GetEstimatedCostDocument = `
    query GetEstimatedCost($country: String!, $currency: String, $parameters: EstimatedCostParameter!) {
  estimatedCost(country: $country, currency: $currency, parameters: $parameters) {
    labelKey
    descriptionKey
    total {
      inCurrencies(currency: ["SGD", "IDR", "USD"]) {
        currency
        amount
      }
    }
    children {
      labelKey
      descriptionKey
      total {
        inCurrencies(currency: ["SGD", "IDR", "USD"]) {
          currency
          amount
        }
      }
      children {
        labelKey
        descriptionKey
        total {
          inCurrencies(currency: ["SGD", "IDR", "USD"]) {
            currency
            amount
          }
        }
        children {
          labelKey
          descriptionKey
          total {
            inCurrencies(currency: ["SGD", "IDR", "USD"]) {
              currency
              amount
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetEstimatedCostQuery = <
      TData = GetEstimatedCostQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetEstimatedCostQueryVariables,
      options?: UseQueryOptions<GetEstimatedCostQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetEstimatedCostQuery, TError, TData>(
      ['GetEstimatedCost', variables],
      fetcher<GetEstimatedCostQuery, GetEstimatedCostQueryVariables>(client, GetEstimatedCostDocument, variables, headers),
      options
    );
export const GetInvoiceByIdsDocument = `
    query GetInvoiceByIds($ids: [UUID!]!) {
  invoiceByIds(ids: $ids) {
    id
    referenceNumber
    isPdfReady
    unpaidAmount {
      currency
      amount
    }
    creditNoteLines {
      amount {
        currency
        amount
      }
    }
    platformPayment {
      id
      referenceId
      method
      paidAt
      createdAt
      invoices {
        id
      }
    }
  }
}
    `;
export const useGetInvoiceByIdsQuery = <
      TData = GetInvoiceByIdsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetInvoiceByIdsQueryVariables,
      options?: UseQueryOptions<GetInvoiceByIdsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetInvoiceByIdsQuery, TError, TData>(
      ['GetInvoiceByIds', variables],
      fetcher<GetInvoiceByIdsQuery, GetInvoiceByIdsQueryVariables>(client, GetInvoiceByIdsDocument, variables, headers),
      options
    );
export const GetInvoicePdfDocument = `
    mutation GetInvoicePdf($id: UUID!) {
  invoicePdf(id: $id) {
    url
  }
}
    `;
export const useGetInvoicePdfMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<GetInvoicePdfMutation, TError, GetInvoicePdfMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<GetInvoicePdfMutation, TError, GetInvoicePdfMutationVariables, TContext>(
      ['GetInvoicePdf'],
      (variables?: GetInvoicePdfMutationVariables) => fetcher<GetInvoicePdfMutation, GetInvoicePdfMutationVariables>(client, GetInvoicePdfDocument, variables, headers)(),
      options
    );
export const GetInvoiceInvoiceSummaryDocument = `
    query GetInvoiceInvoiceSummary {
  invoiceSummary {
    open
    outstanding
    overdue
    totalUnpaid {
      currency
      amount
    }
  }
}
    `;
export const useGetInvoiceInvoiceSummaryQuery = <
      TData = GetInvoiceInvoiceSummaryQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetInvoiceInvoiceSummaryQueryVariables,
      options?: UseQueryOptions<GetInvoiceInvoiceSummaryQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetInvoiceInvoiceSummaryQuery, TError, TData>(
      variables === undefined ? ['GetInvoiceInvoiceSummary'] : ['GetInvoiceInvoiceSummary', variables],
      fetcher<GetInvoiceInvoiceSummaryQuery, GetInvoiceInvoiceSummaryQueryVariables>(client, GetInvoiceInvoiceSummaryDocument, variables, headers),
      options
    );
export const GetInvoicesDocument = `
    query GetInvoices($isClosed: Boolean, $page: Int, $pageSize: Int, $sorts: [InvoiceSortInput!], $paymentLineSortInput: [PaymentLineSortInput!]) {
  invoices(isClosed: $isClosed, page: $page, pageSize: $pageSize, sorts: $sorts) {
    pageNo
    pageSize
    total
    data {
      id
      issueDate
      dueDate
      referenceNumber
      isPdfReady
      closedDate
      paidAmount {
        currency
        amount
      }
      amount {
        currency
        amount
      }
      paymentLines(sorts: $paymentLineSortInput) {
        id
        date
        amount {
          currency
          amount
        }
        payment {
          id
          date
          amount {
            currency
            amount
          }
        }
      }
      dispute {
        id
        invoiceId
        reason
        createdAt
        createdBy {
          id
          name
        }
      }
      platformPayment {
        id
        invoices {
          id
          referenceNumber
        }
        total {
          currency
          amount
        }
        createdAt
        method
        ... on PlatformPaymentBankTransfer {
          beneficiaryName
          bankName
          bankCode
          virtualAccount
          id
          referenceId
          method
          createdAt
          paidAt
        }
      }
    }
  }
}
    `;
export const useGetInvoicesQuery = <
      TData = GetInvoicesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetInvoicesQueryVariables,
      options?: UseQueryOptions<GetInvoicesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetInvoicesQuery, TError, TData>(
      variables === undefined ? ['GetInvoices'] : ['GetInvoices', variables],
      fetcher<GetInvoicesQuery, GetInvoicesQueryVariables>(client, GetInvoicesDocument, variables, headers),
      options
    );
export const GetLastMonthTotalInvoiceDocument = `
    query GetLastMonthTotalInvoice {
  invoiceSummary {
    lastMonthTotal {
      currency
      amount
    }
  }
}
    `;
export const useGetLastMonthTotalInvoiceQuery = <
      TData = GetLastMonthTotalInvoiceQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetLastMonthTotalInvoiceQueryVariables,
      options?: UseQueryOptions<GetLastMonthTotalInvoiceQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetLastMonthTotalInvoiceQuery, TError, TData>(
      variables === undefined ? ['GetLastMonthTotalInvoice'] : ['GetLastMonthTotalInvoice', variables],
      fetcher<GetLastMonthTotalInvoiceQuery, GetLastMonthTotalInvoiceQueryVariables>(client, GetLastMonthTotalInvoiceDocument, variables, headers),
      options
    );
export const GetPaymentCompletedDocument = `
    query GetPaymentCompleted($page: Int!, $pageSize: Int!, $sorts: [PaymentLineSortInput!]) {
  paymentLines(page: $page, pageSize: $pageSize, sorts: $sorts) {
    pageNo
    pageSize
    total
    data {
      payment {
        id
        method
        isPdfReady
        date
      }
      invoice {
        id
        referenceNumber
        amount {
          currency
          amount
        }
        closedDate
        paidAmount {
          currency
          amount
        }
        creditNoteLines {
          amount {
            currency
            amount
          }
        }
      }
    }
  }
}
    `;
export const useGetPaymentCompletedQuery = <
      TData = GetPaymentCompletedQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetPaymentCompletedQueryVariables,
      options?: UseQueryOptions<GetPaymentCompletedQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetPaymentCompletedQuery, TError, TData>(
      ['GetPaymentCompleted', variables],
      fetcher<GetPaymentCompletedQuery, GetPaymentCompletedQueryVariables>(client, GetPaymentCompletedDocument, variables, headers),
      options
    );
export const GetPlatformPaymentDocument = `
    query GetPlatformPayment($referenceId: String!) {
  platformPayment(referenceId: $referenceId) {
    id
    referenceId
    method
    paidAt
    createdAt
    ... on PlatformPaymentBankTransfer {
      beneficiaryName
      bankName
      bankCode
      virtualAccount
      id
      referenceId
      method
      createdAt
      paidAt
    }
    total {
      currency
      amount
    }
    invoices {
      referenceNumber
    }
  }
}
    `;
export const useGetPlatformPaymentQuery = <
      TData = GetPlatformPaymentQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetPlatformPaymentQueryVariables,
      options?: UseQueryOptions<GetPlatformPaymentQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetPlatformPaymentQuery, TError, TData>(
      ['GetPlatformPayment', variables],
      fetcher<GetPlatformPaymentQuery, GetPlatformPaymentQueryVariables>(client, GetPlatformPaymentDocument, variables, headers),
      options
    );
export const GetReceiptPdfDocument = `
    mutation GetReceiptPdf($id: UUID!) {
  paymentPdf(id: $id) {
    url
  }
}
    `;
export const useGetReceiptPdfMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<GetReceiptPdfMutation, TError, GetReceiptPdfMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<GetReceiptPdfMutation, TError, GetReceiptPdfMutationVariables, TContext>(
      ['GetReceiptPdf'],
      (variables?: GetReceiptPdfMutationVariables) => fetcher<GetReceiptPdfMutation, GetReceiptPdfMutationVariables>(client, GetReceiptPdfDocument, variables, headers)(),
      options
    );
export const GetTalentAttendanceLogsDocument = `
    query GetTalentAttendanceLogs($id: UUID!, $period: Month!, $sorts: [AttendanceSortInput!], $status: [AttendanceStatus!]) {
  hubber(id: $id) {
    id
    fullName
    hub
    status
    attendance(period: $period) {
      data(status: $status, sorts: $sorts) {
        date
        clockIn
        clockOut
        status
      }
    }
  }
}
    `;
export const useGetTalentAttendanceLogsQuery = <
      TData = GetTalentAttendanceLogsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetTalentAttendanceLogsQueryVariables,
      options?: UseQueryOptions<GetTalentAttendanceLogsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetTalentAttendanceLogsQuery, TError, TData>(
      ['GetTalentAttendanceLogs', variables],
      fetcher<GetTalentAttendanceLogsQuery, GetTalentAttendanceLogsQueryVariables>(client, GetTalentAttendanceLogsDocument, variables, headers),
      options
    );
export const TimeoffRequestsDocument = `
    query TimeoffRequests($page: Int, $pageSize: Int, $name: String, $status: [EmploymentStatus!], $year: Int!, $month: Int!) {
  hubbers(
    page: $page
    pageSize: $pageSize
    name: $name
    sorts: [{field: FULL_NAME, order: ASC}]
    status: $status
    period: {year: $year, month: $month}
  ) {
    pageNo
    pageSize
    total
    data {
      id
      fullName
      status
      joinDate
      contractEndDate
      resignDate
      timeoffBalance {
        hubberId
        name
        category
        balance(year: $year, month: $month)
        used(year: $year, month: $month)
        available(year: $year, month: $month)
      }
      publicHoliday {
        countryCode
        data(year: $year) {
          date
          day
          name
        }
      }
    }
  }
}
    `;
export const useTimeoffRequestsQuery = <
      TData = TimeoffRequestsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: TimeoffRequestsQueryVariables,
      options?: UseQueryOptions<TimeoffRequestsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<TimeoffRequestsQuery, TError, TData>(
      ['TimeoffRequests', variables],
      fetcher<TimeoffRequestsQuery, TimeoffRequestsQueryVariables>(client, TimeoffRequestsDocument, variables, headers),
      options
    );
export const GetUnassignedUsersCountDocument = `
    query GetUnassignedUsersCount {
  company {
    no_role_count: contactCount(roleIds: [null])
  }
}
    `;
export const useGetUnassignedUsersCountQuery = <
      TData = GetUnassignedUsersCountQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetUnassignedUsersCountQueryVariables,
      options?: UseQueryOptions<GetUnassignedUsersCountQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetUnassignedUsersCountQuery, TError, TData>(
      variables === undefined ? ['GetUnassignedUsersCount'] : ['GetUnassignedUsersCount', variables],
      fetcher<GetUnassignedUsersCountQuery, GetUnassignedUsersCountQueryVariables>(client, GetUnassignedUsersCountDocument, variables, headers),
      options
    );
export const GetUserDocument = `
    query GetUser($userId: UUID!) {
  company {
    name
    billingAddress {
      country
    }
  }
  contact(id: $userId) {
    id
    name
    email
    phone
    department
    jobTitle
    phoneNumber {
      countryCode
      number
      extension
    }
    roles {
      id
    }
  }
}
    `;
export const useGetUserQuery = <
      TData = GetUserQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetUserQueryVariables,
      options?: UseQueryOptions<GetUserQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetUserQuery, TError, TData>(
      ['GetUser', variables],
      fetcher<GetUserQuery, GetUserQueryVariables>(client, GetUserDocument, variables, headers),
      options
    );
export const GetUserRolesDocument = `
    query GetUserRoles($userId: UUID!) {
  contact(id: $userId) {
    id
    roles {
      id
    }
  }
}
    `;
export const useGetUserRolesQuery = <
      TData = GetUserRolesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetUserRolesQueryVariables,
      options?: UseQueryOptions<GetUserRolesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetUserRolesQuery, TError, TData>(
      ['GetUserRoles', variables],
      fetcher<GetUserRolesQuery, GetUserRolesQueryVariables>(client, GetUserRolesDocument, variables, headers),
      options
    );
export const GetUsersCountPerRoleDocument = `
    query GetUsersCountPerRole {
  company {
    all_count: contactCount
    no_role_count: contactCount(roleIds: [null])
    admin_count: contactCount(roleIds: ["PREDEFINED:COMPANY_ADMIN"])
    people_manager_count: contactCount(roleIds: ["PREDEFINED:PEOPLE_MANAGER"])
    payer_count: contactCount(roleIds: ["PREDEFINED:PAYER"])
    viewer_count: contactCount(roleIds: ["PREDEFINED:VIEWER"])
  }
}
    `;
export const useGetUsersCountPerRoleQuery = <
      TData = GetUsersCountPerRoleQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetUsersCountPerRoleQueryVariables,
      options?: UseQueryOptions<GetUsersCountPerRoleQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetUsersCountPerRoleQuery, TError, TData>(
      variables === undefined ? ['GetUsersCountPerRole'] : ['GetUsersCountPerRole', variables],
      fetcher<GetUsersCountPerRoleQuery, GetUsersCountPerRoleQueryVariables>(client, GetUsersCountPerRoleDocument, variables, headers),
      options
    );
export const GetUsersPerRoleDocument = `
    query GetUsersPerRole($roleIds: [RoleId]) {
  company {
    contacts(roleIds: $roleIds) {
      id
      name
      email
      phone
      department
      jobTitle
      updatedAt
      phoneNumber {
        countryCode
        number
        extension
      }
    }
  }
}
    `;
export const useGetUsersPerRoleQuery = <
      TData = GetUsersPerRoleQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetUsersPerRoleQueryVariables,
      options?: UseQueryOptions<GetUsersPerRoleQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetUsersPerRoleQuery, TError, TData>(
      variables === undefined ? ['GetUsersPerRole'] : ['GetUsersPerRole', variables],
      fetcher<GetUsersPerRoleQuery, GetUsersPerRoleQueryVariables>(client, GetUsersPerRoleDocument, variables, headers),
      options
    );
export const InitializeDirectDebitDocument = `
    mutation InitializeDirectDebit($param: InitializeDirectDebitParam!) {
  initializeDirectDebit(param: $param) {
    id
    bankSwiftBicCode
    authorizationUrl
    isActive
    nextCollectionDate
  }
}
    `;
export const useInitializeDirectDebitMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<InitializeDirectDebitMutation, TError, InitializeDirectDebitMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<InitializeDirectDebitMutation, TError, InitializeDirectDebitMutationVariables, TContext>(
      ['InitializeDirectDebit'],
      (variables?: InitializeDirectDebitMutationVariables) => fetcher<InitializeDirectDebitMutation, InitializeDirectDebitMutationVariables>(client, InitializeDirectDebitDocument, variables, headers)(),
      options
    );
export const ResolveMagicLinkDocument = `
    mutation ResolveMagicLink($code: String!) {
  resolveMagicLink(code: $code) {
    tokenType
    accessToken
  }
}
    `;
export const useResolveMagicLinkMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ResolveMagicLinkMutation, TError, ResolveMagicLinkMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ResolveMagicLinkMutation, TError, ResolveMagicLinkMutationVariables, TContext>(
      ['ResolveMagicLink'],
      (variables?: ResolveMagicLinkMutationVariables) => fetcher<ResolveMagicLinkMutation, ResolveMagicLinkMutationVariables>(client, ResolveMagicLinkDocument, variables, headers)(),
      options
    );
export const SendMagicLinkDocument = `
    mutation SendMagicLink($email: String!) {
  sendMagicLink(email: $email)
}
    `;
export const useSendMagicLinkMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SendMagicLinkMutation, TError, SendMagicLinkMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SendMagicLinkMutation, TError, SendMagicLinkMutationVariables, TContext>(
      ['SendMagicLink'],
      (variables?: SendMagicLinkMutationVariables) => fetcher<SendMagicLinkMutation, SendMagicLinkMutationVariables>(client, SendMagicLinkDocument, variables, headers)(),
      options
    );
export const UpdateUserDocument = `
    mutation UpdateUser($id: UUID!, $contact: ContactInput!) {
  updateContact(id: $id, contact: $contact) {
    id
    name
    email
    phone
    phoneNumber {
      countryCode
      number
      extension
    }
    department
    jobTitle
  }
}
    `;
export const useUpdateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>(
      ['UpdateUser'],
      (variables?: UpdateUserMutationVariables) => fetcher<UpdateUserMutation, UpdateUserMutationVariables>(client, UpdateUserDocument, variables, headers)(),
      options
    );
export const UpdateUserProfileDocument = `
    mutation UpdateUserProfile($userProfileInput: ProfileInput!) {
  updateProfile(profile: $userProfileInput) {
    id
    name
    email
    phoneNumber {
      countryCode
      number
      extension
    }
    department
    jobTitle
    roles {
      id
      name
    }
  }
}
    `;
export const useUpdateUserProfileMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateUserProfileMutation, TError, UpdateUserProfileMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateUserProfileMutation, TError, UpdateUserProfileMutationVariables, TContext>(
      ['UpdateUserProfile'],
      (variables?: UpdateUserProfileMutationVariables) => fetcher<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(client, UpdateUserProfileDocument, variables, headers)(),
      options
    );
export const GetCompanyHubberHubsDocument = `
    query GetCompanyHubberHubs {
  company {
    hubberHubs
  }
}
    `;
export const useGetCompanyHubberHubsQuery = <
      TData = GetCompanyHubberHubsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetCompanyHubberHubsQueryVariables,
      options?: UseQueryOptions<GetCompanyHubberHubsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetCompanyHubberHubsQuery, TError, TData>(
      variables === undefined ? ['GetCompanyHubberHubs'] : ['GetCompanyHubberHubs', variables],
      fetcher<GetCompanyHubberHubsQuery, GetCompanyHubberHubsQueryVariables>(client, GetCompanyHubberHubsDocument, variables, headers),
      options
    );
export const ExportExpenseClaimDocument = `
    mutation ExportExpenseClaim($hubberName: String, $hub: [String!], $claimStatus: [ExpenseClaimsStatusFilter!], $hubberStatus: [EmploymentStatus!], $currencyInput: String!) {
  exportExpenseClaim(
    hubberName: $hubberName
    hub: $hub
    claimStatus: $claimStatus
    hubberStatus: $hubberStatus
    currencyInput: $currencyInput
  ) {
    fileUrl
  }
}
    `;
export const useExportExpenseClaimMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ExportExpenseClaimMutation, TError, ExportExpenseClaimMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ExportExpenseClaimMutation, TError, ExportExpenseClaimMutationVariables, TContext>(
      ['ExportExpenseClaim'],
      (variables?: ExportExpenseClaimMutationVariables) => fetcher<ExportExpenseClaimMutation, ExportExpenseClaimMutationVariables>(client, ExportExpenseClaimDocument, variables, headers)(),
      options
    );
export const GetExpenseClaimsDocument = `
    query GetExpenseClaims($page: Int, $pageSize: Int, $sorts: [ExpenseClaimSortInput!], $hubberName: String, $hubberStatus: [EmploymentStatus!], $expenseClaimStatus: [ExpenseClaimsStatusFilter!], $hub: [String!], $baseCurrency: String!) {
  expenseClaims(
    page: $page
    pageSize: $pageSize
    sorts: $sorts
    hubberName: $hubberName
    hubberStatus: $hubberStatus
    expenseClaimStatus: $expenseClaimStatus
    hub: $hub
  ) {
    pageNo
    pageSize
    total
    data {
      id
      ...ExpensesTableExpenseClaim
    }
  }
}
    ${ExpensesTableExpenseClaimFragmentDoc}`;
export const useGetExpenseClaimsQuery = <
      TData = GetExpenseClaimsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetExpenseClaimsQueryVariables,
      options?: UseQueryOptions<GetExpenseClaimsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetExpenseClaimsQuery, TError, TData>(
      ['GetExpenseClaims', variables],
      fetcher<GetExpenseClaimsQuery, GetExpenseClaimsQueryVariables>(client, GetExpenseClaimsDocument, variables, headers),
      options
    );
export const ApproveExpenseClaimsDocument = `
    mutation ApproveExpenseClaims($expenseClaimId: UUID!, $remarks: String, $baseCurrency: String!) {
  approveExpenseClaims(expenseClaimId: $expenseClaimId, remarks: $remarks) {
    id
    ...ExpensesTableExpenseClaim
  }
}
    ${ExpensesTableExpenseClaimFragmentDoc}`;
export const useApproveExpenseClaimsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ApproveExpenseClaimsMutation, TError, ApproveExpenseClaimsMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ApproveExpenseClaimsMutation, TError, ApproveExpenseClaimsMutationVariables, TContext>(
      ['ApproveExpenseClaims'],
      (variables?: ApproveExpenseClaimsMutationVariables) => fetcher<ApproveExpenseClaimsMutation, ApproveExpenseClaimsMutationVariables>(client, ApproveExpenseClaimsDocument, variables, headers)(),
      options
    );
export const RejectExpenseClaimsDocument = `
    mutation RejectExpenseClaims($expenseClaimId: UUID!, $remarks: String, $baseCurrency: String!) {
  rejectExpenseClaims(expenseClaimId: $expenseClaimId, remarks: $remarks) {
    id
    ...ExpensesTableExpenseClaim
  }
}
    ${ExpensesTableExpenseClaimFragmentDoc}`;
export const useRejectExpenseClaimsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<RejectExpenseClaimsMutation, TError, RejectExpenseClaimsMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<RejectExpenseClaimsMutation, TError, RejectExpenseClaimsMutationVariables, TContext>(
      ['RejectExpenseClaims'],
      (variables?: RejectExpenseClaimsMutationVariables) => fetcher<RejectExpenseClaimsMutation, RejectExpenseClaimsMutationVariables>(client, RejectExpenseClaimsDocument, variables, headers)(),
      options
    );
export const ExpenseClaimAttachmentUrlDocument = `
    mutation ExpenseClaimAttachmentUrl($id: UUID!) {
  expenseClaimAttachmentUrl(id: $id) {
    url
  }
}
    `;
export const useExpenseClaimAttachmentUrlMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ExpenseClaimAttachmentUrlMutation, TError, ExpenseClaimAttachmentUrlMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ExpenseClaimAttachmentUrlMutation, TError, ExpenseClaimAttachmentUrlMutationVariables, TContext>(
      ['ExpenseClaimAttachmentUrl'],
      (variables?: ExpenseClaimAttachmentUrlMutationVariables) => fetcher<ExpenseClaimAttachmentUrlMutation, ExpenseClaimAttachmentUrlMutationVariables>(client, ExpenseClaimAttachmentUrlDocument, variables, headers)(),
      options
    );
export const ExportTimeoffRequestsDocument = `
    mutation ExportTimeoffRequests($hubberName: String, $hubberStatus: [EmploymentStatus!], $requestStatus: [TimeOffRequestStatus!], $hub: [String!]) {
  exportTimeoffRequests(
    hubberName: $hubberName
    hubberStatus: $hubberStatus
    requestStatus: $requestStatus
    hub: $hub
  ) {
    fileUrl
  }
}
    `;
export const useExportTimeoffRequestsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ExportTimeoffRequestsMutation, TError, ExportTimeoffRequestsMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ExportTimeoffRequestsMutation, TError, ExportTimeoffRequestsMutationVariables, TContext>(
      ['ExportTimeoffRequests'],
      (variables?: ExportTimeoffRequestsMutationVariables) => fetcher<ExportTimeoffRequestsMutation, ExportTimeoffRequestsMutationVariables>(client, ExportTimeoffRequestsDocument, variables, headers)(),
      options
    );
export const GetTimeOffRequestsDocument = `
    query GetTimeOffRequests($page: Int, $pageSize: Int, $hubberName: String, $requestStatus: [TimeOffRequestStatus!], $sorts: [TimeOffRequestSortInput!], $hubberStatus: [EmploymentStatus!], $hub: [String!], $year: Int!, $month: Int!) {
  timeoffRequests(
    page: $page
    pageSize: $pageSize
    hubberName: $hubberName
    requestStatus: $requestStatus
    sorts: $sorts
    hubberStatus: $hubberStatus
    hub: $hub
  ) {
    pageNo
    pageSize
    total
    data {
      id
      ...TimeOffRequestsTableTimeOffRequest
    }
  }
}
    ${TimeOffRequestsTableTimeOffRequestFragmentDoc}`;
export const useGetTimeOffRequestsQuery = <
      TData = GetTimeOffRequestsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetTimeOffRequestsQueryVariables,
      options?: UseQueryOptions<GetTimeOffRequestsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetTimeOffRequestsQuery, TError, TData>(
      ['GetTimeOffRequests', variables],
      fetcher<GetTimeOffRequestsQuery, GetTimeOffRequestsQueryVariables>(client, GetTimeOffRequestsDocument, variables, headers),
      options
    );
export const ApproveTimeOffRequestDocument = `
    mutation ApproveTimeOffRequest($timeOffId: UUID!, $year: Int!, $month: Int!) {
  approveTimeOffRequest(timeOffId: $timeOffId) {
    id
    ...TimeOffRequestsTableTimeOffRequest
  }
}
    ${TimeOffRequestsTableTimeOffRequestFragmentDoc}`;
export const useApproveTimeOffRequestMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ApproveTimeOffRequestMutation, TError, ApproveTimeOffRequestMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ApproveTimeOffRequestMutation, TError, ApproveTimeOffRequestMutationVariables, TContext>(
      ['ApproveTimeOffRequest'],
      (variables?: ApproveTimeOffRequestMutationVariables) => fetcher<ApproveTimeOffRequestMutation, ApproveTimeOffRequestMutationVariables>(client, ApproveTimeOffRequestDocument, variables, headers)(),
      options
    );
export const RejectTimeOffRequestDocument = `
    mutation RejectTimeOffRequest($timeOffId: UUID!, $remark: String, $year: Int!, $month: Int!) {
  rejectTimeOffRequest(timeOffId: $timeOffId, remark: $remark) {
    id
    ...TimeOffRequestsTableTimeOffRequest
  }
}
    ${TimeOffRequestsTableTimeOffRequestFragmentDoc}`;
export const useRejectTimeOffRequestMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<RejectTimeOffRequestMutation, TError, RejectTimeOffRequestMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<RejectTimeOffRequestMutation, TError, RejectTimeOffRequestMutationVariables, TContext>(
      ['RejectTimeOffRequest'],
      (variables?: RejectTimeOffRequestMutationVariables) => fetcher<RejectTimeOffRequestMutation, RejectTimeOffRequestMutationVariables>(client, RejectTimeOffRequestDocument, variables, headers)(),
      options
    );
export const GetTimeOffRequestDocument = `
    query GetTimeOffRequest($id: UUID!, $year: Int!, $month: Int!) {
  timeoffRequest(id: $id) {
    id
    ...TimeOffRequestsTableTimeOffRequest
  }
}
    ${TimeOffRequestsTableTimeOffRequestFragmentDoc}`;
export const useGetTimeOffRequestQuery = <
      TData = GetTimeOffRequestQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetTimeOffRequestQueryVariables,
      options?: UseQueryOptions<GetTimeOffRequestQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetTimeOffRequestQuery, TError, TData>(
      ['GetTimeOffRequest', variables],
      fetcher<GetTimeOffRequestQuery, GetTimeOffRequestQueryVariables>(client, GetTimeOffRequestDocument, variables, headers),
      options
    );
