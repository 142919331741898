import { TimeOffRequestStatus } from '@/generated/graphql';

export const TIME_OFF_REQUESTS_TABLE_KEY = {
  requestedTime: 'requestedTime',
  employee: 'employee',
  talentHub: 'talentHub',
  timeOffType: 'timeOffType',
  startDate: 'startDate',
  endDate: 'endDate',
  duration: 'duration',
  requestStatus: 'requestStatus',
  actions: 'actions',
} as const;

export const TIME_OFF_REQUESTS_TABLE_TITLE = {
  [TIME_OFF_REQUESTS_TABLE_KEY.requestedTime]: 'Requested Time',
  [TIME_OFF_REQUESTS_TABLE_KEY.employee]: 'Employee',
  [TIME_OFF_REQUESTS_TABLE_KEY.talentHub]: 'TalentHub',
  [TIME_OFF_REQUESTS_TABLE_KEY.timeOffType]: 'Time Off Type',
  [TIME_OFF_REQUESTS_TABLE_KEY.startDate]: 'Start Date',
  [TIME_OFF_REQUESTS_TABLE_KEY.endDate]: 'End Date',
  [TIME_OFF_REQUESTS_TABLE_KEY.duration]: 'Duration',
  [TIME_OFF_REQUESTS_TABLE_KEY.requestStatus]: 'Status',
  [TIME_OFF_REQUESTS_TABLE_KEY.actions]: '',
} as const;

export const requestStatusFiltersOptions = Object.entries(
  TimeOffRequestStatus,
).map(([key, value]) => ({
  text: key,
  value: value,
}));
