import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Modal, type ModalProps } from 'glints-aries/lib/@next';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { alertContent, roleDetailsMapping } from '../../constants';
import {
  type AddEditUserModalProps,
  FormMode,
  type Role,
  type User,
} from '../../interfaces';
import { ModalContentContainer } from '../../RolesPermissionsTabStyle';
import { RoleCardOptionList } from './RoleCardOptionList';
import { UserFields, validationSchema } from './UserFields';
import { getGraphqlClient } from '@/clients/graphql';
import {
  type CreateUserMutation,
  type UpdateUserMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
} from '@/generated/graphql';
import { useAuthToken } from '@/hooks/useAuthToken';
import { useGraphqlError } from '@/hooks/useGraphqlError';
import { errorType } from '@/modules/Error/constants';

export const AddEditUserModal = ({
  isOpen,
  onClose,
  user,
  formMode,
  currentRole,
  updateCurrentUsers,
  users,
  updateFormMode,
  refetchUsersCountPerRole,
  updateShowAlert,
}: AddEditUserModalProps) => {
  const navigate = useNavigate();
  const graphqlClient = getGraphqlClient();
  const { clearAuthToken } = useAuthToken();

  const [step, setStep] = useState(!currentRole ? 1 : 2);
  const [selectedRole, setSelectedRole] = useState<Role | undefined>(
    currentRole,
  );

  const defaultValues = user
    ? {
        name: user?.name ?? '',
        department: user?.department ?? '',
        email: user?.email ?? '',
        jobTitle: user?.jobTitle ?? '',
        phoneNumber: {
          countryCode: user?.phoneNumber?.countryCode ?? '+65',
          number: user?.phoneNumber?.number ?? '',
          extension: user?.phoneNumber?.extension ?? '',
        },
      }
    : undefined;

  const {
    control,
    handleSubmit,
    formState: { isDirty: isFormDirty },
    reset: resetForm,
    setError,
  } = useForm<User>({
    resolver: zodResolver(validationSchema),
    mode: 'onBlur',
    defaultValues,
  });

  const { error: createUserError, mutate: createMutate } =
    useCreateUserMutation<Error, CreateUserMutation>(graphqlClient);

  const { error: updateUserError, mutate: editMutate } = useUpdateUserMutation<
    Error,
    UpdateUserMutation
  >(graphqlClient);

  const onCreate = async (user: User) => {
    const phoneNumber = user.phoneNumber?.number ? user.phoneNumber : null;
    createMutate(
      {
        contact: { ...user, phoneNumber, roleId: [selectedRole] },
      },
      {
        onSuccess: (data) => {
          // TODO if created from overview, show Alert,
          // update the count on button and redirect to manage users page
          // if from Manage Users, update list of users and show alert

          if (updateCurrentUsers) {
            const updatedUsers: User[] = [
              data.addContact as User,
              ...(users || []),
            ];
            updateCurrentUsers(updatedUsers);
          }

          if (updateFormMode) {
            updateFormMode({
              mode: FormMode.VIEW,
              userId: undefined,
              isOpen: false,
            });
          }

          if (refetchUsersCountPerRole) {
            refetchUsersCountPerRole();
          }

          updateShowAlert({
            shouldShowAlert: true,
            formMode: FormMode.CREATE,
            alertContent: `${data?.addContact?.name} ${
              alertContent[FormMode.CREATE]
            } ${roleDetailsMapping[selectedRole as Role].role}`,
          });
          resetForm();
          onClose();
        },
        onError: (err) => {
          const emailExistsErr = 'email already exists';

          const doesEmailExists = err.message.includes(emailExistsErr);

          if (doesEmailExists) {
            setError('email', { message: 'This email already exists' });
          }
        },
      },
    );
  };

  const onEdit = async (userFields: User) => {
    const phoneNumber = userFields.phoneNumber?.number
      ? userFields.phoneNumber
      : null;
    editMutate(
      {
        contact: { ...userFields, phoneNumber, roleId: [selectedRole] },
        id: user?.id,
      },
      {
        onSuccess: (data) => {
          if (updateCurrentUsers) {
            let updatedUsers = [];
            if (currentRole !== selectedRole) {
              updatedUsers = users?.filter(
                (user) => user.id !== data.updateContact?.id,
              ) as User[];
            } else {
              updatedUsers = users?.map((user) => {
                if (user.id === data.updateContact?.id) {
                  return data?.updateContact;
                }

                return user;
              }) as User[];
            }
            updateCurrentUsers(updatedUsers);
          }

          if (updateFormMode) {
            updateFormMode({
              mode: FormMode.VIEW,
              userId: undefined,
              isOpen: false,
            });
          }

          updateShowAlert({
            shouldShowAlert: true,
            formMode: FormMode.EDIT,
            alertContent: `${data?.updateContact?.name} ${
              alertContent[FormMode.EDIT]
            }`,
          });
          resetForm();
          onClose();
        },
        onError: (err) => {
          const emailExistsErr = 'email already exists';

          const doesEmailExists = err.message.includes(emailExistsErr);

          if (doesEmailExists) {
            setError('email', { message: 'This email already exists' });
          }

          if (err.toString().substring(28, 31) === '401') {
            clearAuthToken();
            navigate('/magic-link', {
              state: { errorType: errorType.TOKEN_EXPIRED },
            });
          }
        },
      },
    );
  };

  const handleClose = () => {
    if (currentRole) {
      onClose();
      resetForm();
      setSelectedRole(currentRole);
      setStep(2);

      return;
    }

    setStep(1);
    setSelectedRole(undefined);
    onClose();
    resetForm();
  };

  const handleSelectRole = (newRole: Role) => {
    setSelectedRole(newRole);
  };

  const handleSave = () => {
    if (formMode?.mode === FormMode.EDIT) {
      handleSubmit((dataFields) => onEdit(dataFields))();
      return;
    }
    handleSubmit((dataFields) => onCreate(dataFields))();
  };

  const handleChangeRole = () => {
    setStep(1);
  };

  const modalArgs: Record<number, ModalProps> = {
    1: {
      header: 'Assign a Role to New User',
      primaryAction: {
        label: 'Next',
        action: () => setStep(step + 1),
        disabled: !selectedRole,
      },
      secondaryAction: {
        label: 'Cancel',
        action: handleClose,
      },
      onClose: handleClose,
    },
    2: {
      header:
        currentRole && formMode?.mode === FormMode.EDIT
          ? `Edit ${user?.name}`
          : `Add New ${roleDetailsMapping[selectedRole as Role]?.role}`,
      showBackButton: Boolean(!currentRole),
      onBack: () => setStep(1),
      primaryAction: {
        label: 'Save',
        disabled: !isFormDirty,
        action: handleSave,
      },
      secondaryAction: {
        label: 'Cancel',
        action: handleClose,
      },
      onClose: handleClose,
    },
  };

  const formSteps: Record<number, React.ReactNode> = {
    1: (
      <RoleCardOptionList
        control={control}
        selectedRole={selectedRole || ''}
        onSelectRole={handleSelectRole}
      />
    ),
    2: (
      <UserFields
        role={selectedRole}
        control={control}
        formMode={formMode?.mode}
        handleChangeRole={handleChangeRole}
      />
    ),
  };

  useGraphqlError([createUserError, updateUserError]);

  return (
    <Modal isOpen={isOpen} {...modalArgs[step]}>
      <ModalContentContainer>{formSteps[step]}</ModalContentContainer>
    </Modal>
  );
};
