import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  body {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
    height: 100%;
    background: ${Neutral.B99};
  }

  p {
    margin: 0;
  }

  ul,
  ul li {
    margin:0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;
  }

  ol,
  ol li {
    margin:0;
    padding: 0;
  }
  ol {
    padding-left: 20px;
  }

  *::-webkit-scrollbar {
    width: 4px !important;
    height: 4px !important;
  }
  *::-webkit-scrollbar-thumb {
    background-color: ${Neutral.B68} !important;
    border-radius: 2px !important;
    border: none !important;
  }
  *::-webkit-scrollbar-thumb:hover {
    background-color: ${Neutral.B99} !important;
  }
  *::-webkit-scrollbar-track {
    background-color: ${Neutral.B99} !important;
  }

  /* Hack to adjust the typeform z-index */
  .tf-v1-sidetab {
    z-index: 900 !important;
  }

  /* Override Ant Design styles */
  .ant-tooltip {
    .ant-tooltip-arrow::before, .ant-tooltip-arrow::after {
      clip-path: polygon(1.6568542494923806px 100%, 50% 1.6568542494923806px, 14.34314575050762px 100%, 1.6568542494923806px 100%);
    }
  }
`;
