import { Flex } from 'glints-aries';
import { Badge, Card, Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { space16 } from 'glints-aries/lib/@next/utilities/spacing';

import { type HubCode } from '@/components/atoms/HubLocationText/constants';
import { HubLocationText } from '@/components/atoms/HubLocationText/HubLocationText';
import { FormattedDate } from '@/components/FormattedDate/FormattedDate';
import { CardData } from '@/components/molecules/SideSheet/CardData';
import * as SideSheetStyled from '@/components/molecules/SideSheet/styled.sc';
import { EmploymentStatus, type Hubber } from '@/generated/graphql';
import { computeTenure } from '@/utils/date';

export type HubberDetailsSideSheetProps = {
  isOpen: boolean;
  onClose?: () => void;
  hubberData: Omit<Hubber, 'employmentType' | 'attendance' | 'timeoffBalance'>;
};

export const HubberDetailsSideSheet = ({
  isOpen,
  onClose,
  hubberData,
}: HubberDetailsSideSheetProps) => {
  const { tenureYears, tenureMonths } = computeTenure({
    startDate: hubberData.joinDate,
    endDate: hubberData.resignDate,
  });

  const CardHeading = ({ title }: { title: string }) => (
    <Typography as="div" variant="subtitle2" color={Neutral.B18}>
      {title}
    </Typography>
  );

  const statusBadge = (
    <Badge
      status={
        hubberData.status === EmploymentStatus.Active ? 'success' : 'neutral'
      }
    >
      {hubberData.status === EmploymentStatus.Active ? 'Active' : 'Inactive'}
    </Badge>
  );

  return (
    <SideSheetStyled.SideSheet
      isOpen={isOpen}
      title="Employee's Details"
      onClose={onClose}
      showBackButton={false}
    >
      <Flex flexDirection="column" gap={space16}>
        <Card heading={<CardHeading title="Basic Information" />}>
          <SideSheetStyled.CardRow>
            <CardData label="Full Name" value={hubberData.fullName} />
            <CardData label="Status" value={statusBadge} />
          </SideSheetStyled.CardRow>
          <SideSheetStyled.CardRow>
            <CardData
              label="Tenure"
              value={`${tenureYears} yr ${tenureMonths} mo`}
            />
            <CardData
              label="TalentHub Location"
              value={
                hubberData.hub && (
                  <HubLocationText hubCode={hubberData.hub as HubCode} />
                )
              }
            />
          </SideSheetStyled.CardRow>
          <SideSheetStyled.CardRow>
            <CardData label="Email" value={hubberData.email} />
            <CardData label="Phone" value={hubberData.phoneNumber} />
          </SideSheetStyled.CardRow>
        </Card>
        <Card heading={<CardHeading title="Contract Details" />}>
          <SideSheetStyled.CardRow>
            <CardData label="Job Title" value={hubberData.jobTitle} />
            <CardData
              label="Job Start Date"
              value={
                <FormattedDate date={hubberData.joinDate} textVariant="body1" />
              }
            />
          </SideSheetStyled.CardRow>
          <SideSheetStyled.CardRow>
            <CardData
              label="Job End Date"
              value={
                <FormattedDate
                  date={hubberData.resignDate}
                  textVariant="body1"
                  noDataText="Still present"
                />
              }
            />
            <CardData
              label="Contract Expiry Date"
              value={
                <FormattedDate
                  date={hubberData.contractEndDate}
                  textVariant="body1"
                  noDataText="N/A"
                />
              }
            />
          </SideSheetStyled.CardRow>
        </Card>
      </Flex>
    </SideSheetStyled.SideSheet>
  );
};
