import { Badge, PlainButton } from 'glints-aries/lib/@next';
import { borderRadius8 } from 'glints-aries/lib/@next/utilities/borderRadius';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space4,
  space8,
  space16,
  space24,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

import { breakpoint } from '../../../components/constants';

export const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const TopSection = styled.div`
  position: relative;
  display: flex;
  padding: ${space24} 0;
  gap: ${space16};

  &[data-direct-debit-active='true'] {
    .egiro-banner {
      top: 124px;
    }

    .invoice-banner {
      top: 54px;
      margin-bottom: 54px;
    }
  }

  @media (max-width: ${breakpoint.large}) {
    flex-direction: column-reverse;

    &[data-direct-debit-active='true'] {
      .invoice-banner {
        top: 36px;
        margin-bottom: 36px;
      }
    }
  }

  @media (max-width: ${breakpoint.medium}) {
    flex-direction: column-reverse;

    &[data-direct-debit-active='true'] {
      .egiro-banner {
        top: 92px;
      }

      .invoice-banner {
        top: 36px;
        margin-bottom: 36px;
      }
    }
  }

  @media (max-width: ${breakpoint.small}) {
    &[data-direct-debit-active='true'] {
      .invoice-banner {
        top: 56px;
        margin-bottom: 56px;
      }
    }
  }
`;

export const AwaitingPaymentContainer = styled.div``;

export const EGIROContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${space16};
  border-radius: ${borderRadius8};
  border: 1px solid ${Neutral.B85};
  background: linear-gradient(
    133deg,
    rgba(255, 255, 255, 0.7) 27.79%,
    rgba(255, 255, 255, 0.7) 38.95%,
    rgba(215, 222, 241, 0.7) 74.08%
  );
  box-shadow: 0px 1px 3px 0px rgba(63, 63, 68, 0.15),
    0px 0px 0px 1px rgba(71, 71, 71, 0.05);

  @media (max-width: ${breakpoint.large}) {
    align-items: flex-start;
  }
`;

export const StyledBadge = styled(Badge)`
  position: absolute;
  z-index: 2;
  top: -8px;
`;

export const EGIROContent = styled.div`
  display: flex;
  justify-content: center;
  gap: ${space8};
  align-items: center;
`;

export const EGIROContentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space4};

  .egiro-content {
    margin: 0;
  }
`;

export const SetupeGIROButton = styled(PlainButton)`
  justify-content: start;
  padding: 0;

  p {
    margin: 0;
  }
`;

export const BannerContent = styled.div`
  text-align: left;

  p {
    display: inline-block;
    margin: 0;
    margin-left: ${space4};
  }

  a {
    display: inline-block;
  }
`;
