import { roundDownNearestHalf } from '@/utils/math';

export const renderTimeOffDuration = ({
  timeoffDuration,
}: {
  timeoffDuration: string;
}) => {
  const parseStringtoFloat = parseFloat(timeoffDuration);
  const stringDay = parseStringtoFloat <= 1 ? 'day' : 'days';
  const duration = roundDownNearestHalf(parseStringtoFloat).toFixed(1);
  return `${duration} ${stringDay}`;
};
