import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space8,
  space12,
  space24,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

export const MenuItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space12};
  box-sizing: border-box;
`;

export const GroupContainer = styled.div<{ isCollapsed?: boolean }>`
  margin: ${(props) => (props.isCollapsed ? 0 : `${space8} 0`)};
`;

export const MenuItemStyled = styled.div`
  display: flex;
  padding: ${space12};
  gap: ${space8};
  cursor: pointer;
  color: ${Neutral.B18};
  border-radius: 50px;
  border: 1px solid transparent;
  box-sizing: border-box;

  svg {
    fill: ${Neutral.B40};
    height: 20px;
    width: 20px;
  }

  &:hover {
    background-color: ${Neutral.B99};
    box-shadow: 0px 2px 16px 0px rgba(45, 45, 45, 0.08),
      0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  }

  &[data-selected='true'] {
    background-color: ${Blue.S08};
    border: 1px solid ${Blue.S54};
    color: ${Blue.S99};
    cursor: default;

    svg {
      fill: ${Blue.S99};
    }

    &:hover {
      box-shadow: none;
    }
  }

  &[data-collapsed='true'] {
    width: 46px;

    & > span {
      display: none;
    }

    &:hover {
      width: 100vw;
      max-width: fit-content;
      box-shadow: 0px 2px 16px 0px rgba(45, 45, 45, 0.08),
        0px 31px 41px 0px rgba(45, 45, 45, 0.2);
      background-color: ${Neutral.B99};
      transition: width 0.3s ease;

      &[data-selected='true'] {
        background-color: ${Blue.S08};
      }

      & > span {
        display: block;
        margin-right: ${space8};
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  &[data-mobile='true'] {
    width: 100%;
    border-radius: 0px;
    border: none;
    margin: 0;
    padding: ${space12} ${space24};

    &:hover {
      background-color: ${Neutral.B95};
      box-shadow: none;
    }

    &[data-selected='true'] {
      border: none;
      &:hover {
        background-color: ${Blue.S08};
      }
    }
  }
`;

export const Label = styled.div<{ isMobile?: boolean }>`
  // margin:  ${space8} ${space12};
  margin: ${(props) =>
    props.isMobile ? `${space8} ${space24}` : `${space8} ${space12}`};
`;
