import { type TabModel } from 'glints-aries/lib/@next';

import { Role } from './RolesPermissionsTab/interfaces';

export const companyProfileTabNames = {
  COMPANY_DETAILS: 'COMPANY_DETAILS',
  ROLES_AND_PERMISSIONS: 'ROLES_AND_PERMISSIONS',
  AUTO_PAYMENT: 'AUTO_PAYMENT',
} as const;

export type CompanyProfileTabName =
  (typeof companyProfileTabNames)[keyof typeof companyProfileTabNames];

export const companyProfileTabUrls = {
  [companyProfileTabNames.COMPANY_DETAILS]:
    '/company-settings/company-information',
  [companyProfileTabNames.ROLES_AND_PERMISSIONS]:
    '/company-settings/roles-and-permissions',
  [companyProfileTabNames.AUTO_PAYMENT]: '/company-settings/auto-payment',
};

export type CompanyProfileTabUrl = ValueOf<typeof companyProfileTabUrls>;

export const companyProfileTabs = {
  [companyProfileTabNames.COMPANY_DETAILS]: {
    id: 'company-information-tab',
    content: 'Company Information',
  },
  [companyProfileTabNames.ROLES_AND_PERMISSIONS]: {
    id: 'roles-and-permissions-tab',
    content: 'Roles and Permissions',
  },
  [companyProfileTabNames.AUTO_PAYMENT]: {
    id: 'auto-payment-tab',
    content: 'Auto Payment',
  },
};

export type CompanyProfileTabIndex = Record<CompanyProfileTabName, number>;
export type CompanyProfileIndexToTabName = Record<
  number,
  CompanyProfileTabName
>;
export type CompanyProfileTabUrlToTabIndex = Record<
  CompanyProfileTabUrl,
  number
>;

export const createTabConfiguration = (
  permittedTabs: CompanyProfileTabName[],
): {
  tabs: TabModel[];
  tabIndexMap: CompanyProfileTabIndex;
  indexToTabNameMap: CompanyProfileIndexToTabName;
  tabUrltoTabIndexMap: CompanyProfileTabUrlToTabIndex;
} => {
  const tabs: TabModel[] = [];
  const tabIndexMap: CompanyProfileTabIndex = {} as CompanyProfileTabIndex;
  const indexToTabNameMap = {} as CompanyProfileIndexToTabName;
  const tabUrltoTabIndexMap = {} as CompanyProfileTabUrlToTabIndex;

  permittedTabs.forEach((tabName, index) => {
    const tabInfo = companyProfileTabs[tabName];
    const tabUrl = companyProfileTabUrls[tabName];

    if (tabInfo) {
      tabs.push(companyProfileTabs[tabName]);
      tabIndexMap[tabName] = index;
      indexToTabNameMap[index] = tabName;
      tabUrltoTabIndexMap[tabUrl] = index;
    }
  });

  return { tabs, tabIndexMap, indexToTabNameMap, tabUrltoTabIndexMap };
};

export const PERMITTED_MANAGE_USERS_ROLE = [Role.CompanyAdmin];
