import { borderRadius8 } from 'glints-aries/lib/@next/utilities/borderRadius';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import {
  space4,
  space8,
  space16,
  space24,
  space32,
  space40,
} from 'glints-aries/lib/@next/utilities/spacing';
import styled from 'styled-components';

import { BREAKPOINTS } from '@/constants/breakpoints';

const TYPEFORM_HEIGHT = '320px';

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${space24};

  @media (max-width: ${BREAKPOINTS.large}) {
    flex-direction: column;
  }
`;

export const FlexCard = styled.div`
  flex: 1;

  .card-container .card-content-container {
    height: ${TYPEFORM_HEIGHT};

    @media (max-width: ${BREAKPOINTS.small}) {
      height: unset;
    }
  }
`;

export const ResourcesCard = styled(FlexCard)`
  .card-container {
    .card-content-container {
      background: linear-gradient(
        152.47deg,
        #dfedf4 13.59%,
        #ffffff 50.41%,
        #f2effe 83.42%
      );
      padding-bottom: 0;
      border-radius: 0 0 ${borderRadius8} ${borderRadius8};
    }
  }
`;

export const TypeFormCard = styled(FlexCard)`
  .offer-thoughts-typeform-widget {
    height: ${TYPEFORM_HEIGHT};
    background: ${Neutral.B99};
  }
`;

export const ResourcesCardContent = styled.div`
  position: relative;
  padding: ${space24} ${space4} ${space24} ${space40};
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: ${space4};
    height: calc(100% - 8px);
  }
`;

export const ResourcesSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space16};
`;

export const ResourcesCardArt = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
`;

export const GreetingHeading = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;

  .greeting-heading-text {
    display: flex;
    flex-direction: column;
    gap: ${space16};
  }
`;

export const UrgentCard = styled.div`
  margin-bottom: ${space24};

  .card-container .card-content-container {
    background: ${Neutral.B99};
    border-radius: 0 0 ${borderRadius8} ${borderRadius8};
  }
`;

export const UrgentCardContent = styled.div`
  display: flex;
  gap: ${space32};

  @media (max-width: ${BREAKPOINTS.small}) {
    flex-direction: column-reverse;
    align-items: center;
    gap: ${space8};
  }
`;

export const UrgentCardContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space16};

  button {
    width: fit-content;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    align-items: center;
  }
`;

export const UrgentCardContentRight = styled.div`
  svg {
    width: 150px;
    height: 150px;
  }
`;
