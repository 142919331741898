import { useState } from 'react';
import { Avatar, Badge, Icon, Typography } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';

import { type Role } from '../../..//modules/CompanyProfile/RolesPermissionsTab/interfaces';
import { roleDetailsMapping } from '../../../modules/CompanyProfile/RolesPermissionsTab/constants';
import { getInitials } from '../../../utils/formatString';
import {
  CollapsedUserSection,
  Content,
  IconContainer,
  RightContainer,
  StyledCollapsibleFooter,
  UserAccountSection,
} from './CollapsibleFooterStyle';

interface CollapsibleFooterProps {
  children?: React.ReactNode;
  isNavCollapsed: boolean;
  updateNavCollapsed(newValue: boolean): void;
  jobTitle?: string | null;
  name?: string;
  role?: Role;
}
export const CollapsibleFooter = ({
  children,
  isNavCollapsed,
  updateNavCollapsed,
  jobTitle,
  name,
  role,
}: CollapsibleFooterProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (isNavCollapsed) {
      updateNavCollapsed(false);
    }

    setIsCollapsed(!isCollapsed);
  };

  const userSection = isNavCollapsed ? (
    <CollapsedUserSection data-nav-collapsed={isNavCollapsed}>
      {name && (
        <Avatar
          className="user-avatar"
          initials={getInitials(name)}
          variant="warning"
        />
      )}
      <div className="job-title">
        <Typography as="span" variant="caption" color={Neutral.B00}>
          {jobTitle}
        </Typography>
        <IconContainer isCollapsed={isCollapsed}>
          <Icon name="ri-arrow-m-down-fill" fill={Neutral.B40} height="24px" />
        </IconContainer>
      </div>
    </CollapsedUserSection>
  ) : (
    <>
      {name && (
        <Avatar
          className="user-avatar"
          initials={getInitials(name)}
          variant="warning"
        />
      )}
      <RightContainer className="user-profile-right-section">
        <Typography
          as="span"
          variant="caption"
          color={jobTitle ? Neutral.B00 : Neutral.B68}
        >
          {jobTitle ?? 'Title Not Specified'}
        </Typography>
        {role && (
          <Badge status="neutral">{roleDetailsMapping[role].role}</Badge>
        )}
      </RightContainer>
      <IconContainer isCollapsed={isCollapsed}>
        <Icon name="ri-arrow-m-down-fill" fill={Neutral.B40} height="24px" />
      </IconContainer>
    </>
  );

  return (
    <StyledCollapsibleFooter data-nav-collapsed={isNavCollapsed}>
      <UserAccountSection onClick={handleClick}>
        {userSection}
      </UserAccountSection>
      <Content isCollapsed={isCollapsed}>{children}</Content>
    </StyledCollapsibleFooter>
  );
};
