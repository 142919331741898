import { type Control } from 'react-hook-form';

import { type RoleUnassigned } from './constants';

export enum Role {
  CompanyAdmin = 'PREDEFINED:COMPANY_ADMIN',
  PeopleManager = 'PREDEFINED:PEOPLE_MANAGER',
  Payer = 'PREDEFINED:PAYER',
  Viewer = 'PREDEFINED:VIEWER',
}

export interface RolesPermissionsCardProps {
  role: string;
  roleDetails: string;
  userCount?: number;
  loadingUserCount: boolean;
  onManageUsersClick(): void;
  onViewPermissions(): void;
}

export type Phone = {
  countryCode: string;
  extension?: string;
  number: string;
};

export type User = {
  department: string;
  email: string;
  id?: string;
  jobTitle: string;
  name: string;
  phone: string;
  phoneNumber: Phone;
  roleId: Role[];
};

export enum FormMode {
  CREATE = 'create',
  DELETE = 'delete',
  EDIT = 'edit',
  VIEW = 'view',
}

export type UserInput = Omit<User, 'id'>;

export interface AddEditUserProps {
  user?: User;
  // formMode: FormMode;
  apiError?: Error | null;
  isLoading?: boolean;
  // onCancel(): void;
  // onSave(data: UserInput): void;
  step: number;
}

export interface AddEditUserModalProps {
  isOpen?: boolean;
  onClose(): void;
  user?: User;
  formMode?: {
    mode: FormMode;
    userId?: string;
    isOpen: boolean;
  };
  currentRole?: Role;
  formStep?: number;
  updateCurrentUsers?(newUsers: User[]): void;
  updateFormMode?(newMode: {
    mode: FormMode;
    userId?: string;
    isOpen: boolean;
  }): void;
  users?: User[];
  refetchUsersCountPerRole?(): void;
  updateShowAlert({
    shouldShowAlert,
    formMode,
    alertContent,
  }: {
    shouldShowAlert: boolean;
    formMode?: FormMode;
    alertContent: string;
  }): void;
}

export interface UserFieldsProps {
  apiError?: Error | null;
  control?: Control<User>;
  role?: Role;
  user?: User;
  formMode?: FormMode;
  handleChangeRole?(): void;
}

export interface RoleCardOptionListProps {
  control?: Control<User>;
  selectedRole: Role | string;
  onSelectRole(newRole: Role): void;
}

export enum UserRoleCount {
  ALL = 'all_count',
  ADMIN = 'admin_count',
  NONE = 'no_role_count',
  PEOPLE_MANAGER = 'people_manager_count',
  PAYER = 'payer_count',
  VIEWER = 'viewer_count',
}

export type GetUsersCountPerRoleQuery = {
  company: Record<UserRoleCount, number>;
};

export type GetUsersPerRoleQuery = {
  company: {
    contacts: User[];
  };
};

export interface ManageUsersProps {
  role?: Role | RoleUnassigned;
  updateIsManageUsers(newValue: boolean): void;
}

export interface RolesPermissionsOverviewProps {
  selectedRole?: Role | RoleUnassigned;
  updateIsManageUsers(newValue: boolean): void;
  updateSelectedRole(newValue: Role | RoleUnassigned): void;
}

export enum PermissionFeature {
  MY_EMPLOYEES = 'Employees',
  ATTENDANCE = 'Attendance',
  TIME_OFF = 'Time Off',
  EXPENSES = 'Expenses',
  INVOICING = 'Invoice Payments',
  COMPANY_SETTINGS = 'Company Settings',
}

export enum Permission {
  READ = 'READ',
  WRITE = 'WRITE',
  DELETE = 'DELETE',
}
