import { useEffect, useState } from 'react';
import { Sidetab } from '@typeform/embed-react';
import { Box } from 'glints-aries';
import { Alert, Portal } from 'glints-aries/lib/@next';
import { Blue, Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';

import { CollapsibleFooter } from './components/CollapsibleFooter';
import { Header } from './components/Header';
import { LogoutButton } from './components/LogoutButton';
import { MyProfileButton } from './components/MyProfileButton';
import {
  alertContent,
  AlertType,
  menuIcons,
  menuLabels,
  type MenuName,
  menuNames,
  menuUrls,
  menuUrlSegments,
} from './constants';
import {
  MenuContainer,
  MenuMobileContainer,
  MenuMobileSideContainer,
  MenuMobileWrapper,
} from './MenuStyle';
import {
  MainPageContainer,
  PageLayoutContainer,
  StyledHomeBackground,
} from './PageLayoutStyle';
import { Menu, type MenuProps } from '@/components/atoms/Menu/Menu';
import { menuType } from '@/components/atoms/Menu/types';
import { type User } from '@/components/organisms/Modals/MyProfileModal/interfaces';
import { MyProfileModal } from '@/components/organisms/Modals/MyProfileModal/MyProfileModal';
import { useAuthContext } from '@/components/particles/AuthInfoProvider/AuthInfoProvider';
import { useFeatureFlagContext } from '@/components/particles/FeatureFlagProvider/FeatureFlagProvider';
import { UNLEASH_FLAG_NAME } from '@/constants/constants';
import { useScreen } from '@/hooks/screen';

interface PageLayoutProps {
  component: JSX.Element;
  direct?: boolean; // if true, redirect to /login immediately if not authenticated
  pageTitle?: string;
  metaDescription?: string;
  centered?: boolean; // make content vertically and horizontally centered
  disabledPadding?: boolean; // This is for the correct implementing page purpose. If true, remove MainPageContainer's padding
}

const menuItems: MenuProps['items'] = [
  {
    key: 'my-team-group',
    label: 'My Team',
    type: menuType.GROUP,
    children: [
      {
        key: menuNames.EMPLOYEES,
        label: menuLabels[menuNames.EMPLOYEES],
        iconName: menuIcons[menuNames.EMPLOYEES].icon,
        selectedIconName: menuIcons[menuNames.EMPLOYEES].selectedIcon,
      },
      {
        key: menuNames.ATTENDANCE,
        label: menuLabels[menuNames.ATTENDANCE],
        iconName: menuIcons[menuNames.ATTENDANCE].icon,
        selectedIconName: menuIcons[menuNames.ATTENDANCE].selectedIcon,
      },
      {
        key: menuNames.TIME_OFF,
        label: menuLabels[menuNames.TIME_OFF],
        iconName: menuIcons[menuNames.TIME_OFF].icon,
        selectedIconName: menuIcons[menuNames.TIME_OFF].selectedIcon,
      },
    ],
  },
  {
    key: 'my-company-group',
    label: 'My Company',
    type: menuType.GROUP,
    children: [
      {
        key: menuNames.INVOICE_PAYMENTS,
        label: menuLabels[menuNames.INVOICE_PAYMENTS],
        iconName: menuIcons[menuNames.INVOICE_PAYMENTS].icon,
        selectedIconName: menuIcons[menuNames.INVOICE_PAYMENTS].selectedIcon,
      },
      {
        key: menuNames.COMPANY_SETTINGS,
        label: menuLabels[menuNames.COMPANY_SETTINGS],
        iconName: menuIcons[menuNames.COMPANY_SETTINGS].icon,
        selectedIconName: menuIcons[menuNames.COMPANY_SETTINGS].selectedIcon,
      },
    ],
  },
];

const PageLayout = ({
  component,
  direct = false,
  pageTitle = 'Glints Managed Talent',
  metaDescription = '',
  centered = false,
  disabledPadding = false,
}: PageLayoutProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const featureFlagContext = useFeatureFlagContext();
  const { useFlagEnabled } = featureFlagContext;

  const { userInfo } = useAuthContext();
  const [showAlert, setShowAlert] = useState({
    shouldShow: false,
    type: AlertType.SUCCESS,
  });
  const isLargeScreen = useScreen();
  const isExpenseClaimShown = useFlagEnabled(
    UNLEASH_FLAG_NAME.mtsShowExpenseClaim,
  );

  // NOTE: Hide the Expense Claim feature before we release Assign Report Manager feature
  // JIRA: https://glints.atlassian.net/browse/MTS-1049
  useEffect(() => {
    if (!menuItems[0].children) return;

    if (isExpenseClaimShown && menuItems[0].children.length === 3) {
      menuItems[0].children.push({
        key: menuNames.EXPENSES,
        label: menuLabels[menuNames.EXPENSES],
        iconName: menuIcons[menuNames.EXPENSES].icon,
        selectedIconName: menuIcons[menuNames.EXPENSES].selectedIcon,
      });
    }
  }, [isExpenseClaimShown]);

  const [selectedMenuKey, setSelectedMenuKey] = useState<string>();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState<boolean>(false);
  const [user, setUser] = useState<User | undefined>();

  useEffect(() => {
    if (!isLargeScreen) {
      setIsCollapsed(true);
    }
  }, [isLargeScreen]);

  useEffect(() => {
    for (const [key, segment] of Object.entries(menuUrlSegments)) {
      if (location.pathname.includes(segment)) {
        setSelectedMenuKey(key);
      }
    }
  }, [location]);

  const handleProfileModalClose = () => setIsProfileModalOpen(false);

  const handleCollapseClick = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  const handleMenuClick = (key: MenuName) => {
    setSelectedMenuKey(key);
    navigate(menuUrls[key]);
    if (!isLargeScreen) setIsCollapsed(true);
  };

  const handleNavigateToHomePage = () => {
    setSelectedMenuKey(undefined);
    navigate('/');
    if (!isLargeScreen) setIsCollapsed(true);
  };

  const updateUser = (data: User) => {
    setUser(data);
  };

  const updateShowAlert = (showAlertValue: {
    shouldShow: boolean;
    type: AlertType;
  }) => {
    setShowAlert(showAlertValue);
  };

  useEffect(() => {
    if (userInfo) {
      updateUser(userInfo.contact);
    }
  }, [userInfo]);

  const MenuComponent = () =>
    isLargeScreen ? (
      <>
        <MenuContainer data-collapsed={isCollapsed}>
          <Header
            isCollapsed={isCollapsed}
            onCollapseClick={handleCollapseClick}
            isLargeScreen={isLargeScreen}
            onLogoClick={handleNavigateToHomePage}
          />
          <Box px={12}>
            <Menu
              items={menuItems}
              selectedKey={selectedMenuKey}
              onClick={(key: string) => handleMenuClick(key as MenuName)}
              isCollapsed={isCollapsed}
            />
          </Box>
          <CollapsibleFooter
            isNavCollapsed={isCollapsed}
            name={userInfo?.contact.name}
            role={userInfo?.contact.roles[0].id}
            jobTitle={userInfo?.contact.jobTitle}
            updateNavCollapsed={(newValue: boolean) => setIsCollapsed(newValue)}
          >
            <MyProfileButton
              isCollapsed={isCollapsed}
              handleClick={() => setIsProfileModalOpen(true)}
            />
            <LogoutButton isCollapsed={isCollapsed} />
          </CollapsibleFooter>
        </MenuContainer>
      </>
    ) : isCollapsed ? (
      <MenuMobileContainer>
        <Header
          isCollapsed={true}
          onCollapseClick={handleCollapseClick}
          isLargeScreen={isLargeScreen}
          onLogoClick={handleNavigateToHomePage}
        />
      </MenuMobileContainer>
    ) : (
      <>
        <MenuMobileContainer>
          <Header
            isCollapsed={true}
            onCollapseClick={handleCollapseClick}
            isLargeScreen={isLargeScreen}
            onLogoClick={handleNavigateToHomePage}
            faded={true}
          />
        </MenuMobileContainer>
        <Portal>
          <MenuMobileWrapper>
            <MenuMobileSideContainer>
              <Header
                isCollapsed={false}
                onCollapseClick={handleCollapseClick}
                isLargeScreen={isLargeScreen}
              />
              <Box py={8}>
                <Menu
                  items={menuItems}
                  selectedKey={selectedMenuKey}
                  onClick={(key: string) => handleMenuClick(key as MenuName)}
                  isCollapsed={isCollapsed}
                  isMobile={true}
                />
              </Box>
              <CollapsibleFooter
                isNavCollapsed={isCollapsed}
                name={userInfo?.contact.name}
                role={userInfo?.contact.roles[0].id}
                jobTitle={userInfo?.contact.jobTitle}
                updateNavCollapsed={(newValue: boolean) =>
                  setIsCollapsed(newValue)
                }
              >
                <MyProfileButton
                  isCollapsed={isCollapsed}
                  handleClick={() => setIsProfileModalOpen(true)}
                />
                <LogoutButton isCollapsed={isCollapsed} />
              </CollapsibleFooter>
            </MenuMobileSideContainer>
          </MenuMobileWrapper>
        </Portal>
      </>
    );

  return (
    <>
      <MyProfileModal
        isOpen={isProfileModalOpen}
        onClose={handleProfileModalClose}
        user={user}
        updateUser={updateUser}
        updateShowAlert={updateShowAlert}
      />
      <Sidetab
        id="EhGEiKaG"
        buttonText="Report"
        customIcon="https://glints-dashboard-dev.s3.ap-southeast-1.amazonaws.com/images/mts/layout/questionnaire-icon.svg"
        buttonTextColor={Blue.S99}
        buttonColor={Neutral.B100}
        hidden={{
          name: userInfo?.contact?.name || '',
          email: userInfo?.contact?.email || '',
          company: userInfo?.company?.name || '',
          /* eslint-disable camelcase */
          user_id: userInfo?.contact?.id || '',
          user_role: userInfo?.contact?.roles[0].id || '',
          job_title: userInfo?.contact?.jobTitle || '',
        }}
      />
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={metaDescription} />
        </Helmet>
        <StyledHomeBackground />
        <PageLayoutContainer className="page-layout-container">
          <MenuComponent />
          <MainPageContainer
            data-collapsed={isCollapsed}
            data-hasframe={!direct}
            data-centered={centered}
            disabledPadding={disabledPadding}
            className="main-page-container"
          >
            {component}
          </MainPageContainer>
        </PageLayoutContainer>
      </>
      <Alert
        show={showAlert.shouldShow}
        onDismissed={() =>
          setShowAlert({
            ...showAlert,
            shouldShow: false,
          })
        }
        content={alertContent[showAlert.type]}
        status={showAlert.type}
      />
    </>
  );
};

export default PageLayout;
