import { SkeletonImageSquare } from 'glints-aries/lib/@next';

import { type GlintsAntdTableProps } from './GlintsAntdTable';

export const renderSkeletonColumns = (
  columns: GlintsAntdTableProps['columns'],
) =>
  columns.map((column) => ({
    ...column,
    render: function renderPlaceholder() {
      return <SkeletonImageSquare height="24px" width="100%" />;
    },
  }));

export const renderSkeletonDataSource = (rowsNumber = 10) =>
  [...Array(rowsNumber)].map((_, index) => ({
    key: `key${index}`,
  }));
