import { useState } from 'react';
import { Widget } from '@typeform/embed-react';
import { Box } from 'glints-aries';
import {
  Card,
  Icon,
  InlineError,
  PrimaryButton,
  Typography,
} from 'glints-aries/lib/@next';
import { Blue } from 'glints-aries/lib/@next/utilities/colors';
import { useNavigate } from 'react-router-dom';

import { PERMITTED_MANAGE_USERS_ROLE } from '../CompanyProfile/constants';
import * as Styled from './HomePageStyle';
import AstronautStarsSVG from '@/assets/images/astronaut-stars.svg';
import { ReactComponent as AstronautSupportSVG } from '@/assets/images/astronaut-support.svg';
import { getGraphqlClient } from '@/clients/graphql';
import { IllustrationPlanet } from '@/components/atoms/Illustrations/Planet/Planet';
import { CostCalculatorModal } from '@/components/organisms/Modals/CostCalculatorModal/CostCalculatorModal';
import { BudgetToolButton } from '@/components/organisms/Resources/BudgetToolButton/BudgetToolButton';
import { CostCalculatorButton } from '@/components/organisms/Resources/CostCalculatorButton/CostCalculatorButton';
import { useAuthContext } from '@/components/particles/AuthInfoProvider/AuthInfoProvider';
import {
  type GetUnassignedUsersCountQuery,
  useGetUnassignedUsersCountQuery,
} from '@/generated/graphql';

const HomePage = () => {
  const graphqlClient = getGraphqlClient();
  const navigate = useNavigate();
  const { userInfo } = useAuthContext();
  const currentUserRole = userInfo?.contact?.roles[0].id;
  const [isCostCalculatorModalOpen, setIsCostCalculatorModalOpen] =
    useState(false);

  const { data: unassignedUsersCountQuery } = useGetUnassignedUsersCountQuery<
    GetUnassignedUsersCountQuery,
    Error
  >(graphqlClient);

  return (
    <>
      <Styled.GreetingHeading>
        <div className="greeting-heading-text">
          <Typography as="span" variant="headline3" color={Blue.S100}>
            Hi, {userInfo?.contact.name}
          </Typography>
          <Typography as="span" color={Blue.S100} variant="body1">
            Begin your journey by selecting an option from the menu on the left
          </Typography>
        </div>
        <IllustrationPlanet />
      </Styled.GreetingHeading>

      {PERMITTED_MANAGE_USERS_ROLE.includes(currentUserRole) && (
        <Styled.UrgentCard>
          <Card
            heading={
              <Typography as="span" variant="body2">
                Urgent Actions Needed
              </Typography>
            }
          >
            <Card.Section>
              <Styled.UrgentCardContent>
                <Styled.UrgentCardContentLeft>
                  <Box>
                    <Typography as="span" variant="body1">
                      Please review your company settings to ensure{' '}
                    </Typography>
                    <Typography as="span" variant="body2">
                      that all users are up-to-date, safeguarding your
                      confidential data.
                    </Typography>{' '}
                    <Typography as="span" variant="body1">
                      You&apos;ll be able to assign users to four different
                      roles: Company Admin, Payer, People Manager, and Viewer.
                      To learn more, please click &apos;Manage Users Now&apos;.
                    </Typography>
                  </Box>
                  <PrimaryButton
                    icon={<Icon name="ri-arrow-m-right-line" />}
                    iconPosition="right"
                    onClick={() =>
                      navigate('/company-settings/roles-and-permissions')
                    }
                  >
                    Manage Users Now
                  </PrimaryButton>
                  {unassignedUsersCountQuery &&
                    unassignedUsersCountQuery?.company?.no_role_count > 0 && (
                      <InlineError
                        text={`${unassignedUsersCountQuery?.company.no_role_count} invalid users awaiting review`}
                      />
                    )}
                </Styled.UrgentCardContentLeft>

                <Styled.UrgentCardContentRight>
                  <AstronautSupportSVG />
                </Styled.UrgentCardContentRight>
              </Styled.UrgentCardContent>
            </Card.Section>
          </Card>
        </Styled.UrgentCard>
      )}

      <Styled.FlexRow>
        <Styled.ResourcesCard>
          <Card
            heading={
              <Typography as="span" variant="body2">
                Explore Your Resources
              </Typography>
            }
          >
            <Styled.ResourcesCardContent>
              <Card.Section>
                <Styled.ResourcesSectionContent>
                  <CostCalculatorButton
                    onClick={() => setIsCostCalculatorModalOpen(true)}
                  />
                  <BudgetToolButton />
                </Styled.ResourcesSectionContent>
              </Card.Section>
              <Styled.ResourcesCardArt src={AstronautStarsSVG} />
            </Styled.ResourcesCardContent>
          </Card>
        </Styled.ResourcesCard>

        <Styled.TypeFormCard>
          <Card
            heading={
              <Typography as="span" variant="body2">
                Offer Your Thoughts
              </Typography>
            }
          >
            <Widget
              id="PJJhH9Kr"
              className="offer-thoughts-typeform-widget"
              hidden={{
                /* eslint-disable camelcase */
                user_id: userInfo?.contact?.id || '',
                name: userInfo?.contact?.name || '',
                email: userInfo?.contact?.email || '',
                company: userInfo?.company?.name || '',
              }}
            />
          </Card>
        </Styled.TypeFormCard>
      </Styled.FlexRow>

      <CostCalculatorModal
        isOpen={isCostCalculatorModalOpen}
        showCloseButton={true}
        onClose={() => setIsCostCalculatorModalOpen(false)}
      />
    </>
  );
};

export default HomePage;
