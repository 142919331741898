import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from 'glints-aries';
import {
  ButtonGroup,
  Card,
  Divider,
  EmptyState,
  Icon,
  type ModalProps,
  OutlineButton,
  PrimaryButton,
  SkeletonText,
  Tooltip,
  Typography,
} from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { ComputationResult } from './ComputationResult/ComputationResult';
import {
  CONTRACT_TYPE,
  contractTypeOptions,
  COST_CALCULATOR_FORM_HELPTEXT,
  COST_CALCULATOR_FORM_LABEL,
  COST_CALCULATOR_FORM_NAMES,
  COST_CALCULATOR_FORM_PLACEHOLDER,
  type CostCalculatorFields,
  countries,
  COUNTRY_CODE,
  countryOptions,
  FORM_NUMBER_INPUT_SUFFIX,
  MINIMUM_WAGE,
  SUPPORTED_COUNTRIES,
  TEAM_EXPANSION_URL,
} from './constants';
import { FormPaymentCurrencySelect } from './FormPaymentCurrencySelect/FormPaymentCurrencySelect';
import * as Styled from './styled.sc';
import AstronautImage from '@/assets/images/astronaut-planets.svg';
import { ReactComponent as NebulaSVG } from '@/assets/images/nebula.svg';
import { getGraphqlClient } from '@/clients/graphql';
import { FormCurrencyInput } from '@/components/molecules/Forms/FormCurrencyInput/FormCurrencyInput';
import { FormNumberInput } from '@/components/molecules/Forms/FormNumberInput/FormNumberInput';
import { FormSelect } from '@/components/molecules/Forms/FormSelect/FormSelect';
import { UnderDevelopment } from '@/components/molecules/UnderDevelopment/UnderDevelopment';
import { Currency, type CurrencyType } from '@/constants/currency';
import {
  type CostStructure,
  type GetEstimatedCostQuery,
  useGetEstimatedCostQuery,
} from '@/generated/graphql';

const validationSchema = z.object({
  /* eslint-disable camelcase */
  country: z
    .string({ required_error: 'Required', invalid_type_error: 'Required' })
    .min(1, 'Required'),
  contractType: z.string({
    invalid_type_error: 'type error',
    required_error: 'Required',
  }),
  contractPeriod: z.coerce
    .number()
    .int()
    .gte(
      12,
      COST_CALCULATOR_FORM_HELPTEXT[COST_CALCULATOR_FORM_NAMES.contractPeriod],
    )
    .lte(
      60,
      COST_CALCULATOR_FORM_HELPTEXT[COST_CALCULATOR_FORM_NAMES.contractPeriod],
    )
    .nullish(),
  monthlyGrossSalary: z.coerce
    .number({
      invalid_type_error:
        COST_CALCULATOR_FORM_HELPTEXT[
          COST_CALCULATOR_FORM_NAMES.monthlyGrossSalary
        ],
    })
    .int()
    .gte(
      MINIMUM_WAGE[COUNTRY_CODE.indonesia],
      COST_CALCULATOR_FORM_HELPTEXT[
        COST_CALCULATOR_FORM_NAMES.monthlyGrossSalary
      ],
    ),
});

const StepLabel = ({
  stepNumber,
  label,
}: {
  stepNumber: string;
  label: string;
}) => (
  <Styled.StepLabel>
    <Styled.StepNumber>
      <Typography as="span" color={Neutral.B00} variant="body2">
        {stepNumber}
      </Typography>
    </Styled.StepNumber>
    <Typography as="span" color={Neutral.B18} variant="body2">
      {label}
    </Typography>
  </Styled.StepLabel>
);

const NoEstimateEmptyState = (
  <Styled.EmptyStateContainer>
    <EmptyState
      title="No estimate yet"
      description="Enter the relevant details for your role on the left"
      image={<NebulaSVG />}
    />
  </Styled.EmptyStateContainer>
);

const LoadingState = (
  <Styled.LoadingStateContainer>
    <SkeletonText rows={Array(14).fill({ variant: 'subtitle2' })} />
  </Styled.LoadingStateContainer>
);

const UnderDevelopmentState = (
  <Styled.UnderDevelopmentStateContainer>
    <UnderDevelopment />
  </Styled.UnderDevelopmentStateContainer>
);

export const CostCalculatorModal = ({
  isOpen,
  onClose,
  ...props
}: ModalProps) => {
  const graphqlClient = getGraphqlClient();
  const [isMonthly, setIsMonthly] = useState(true);
  const [estimatedCostData, setEstimatedCostData] =
    useState<CostStructure | null>();

  const { control, handleSubmit, formState, setValue, reset, watch } =
    useForm<CostCalculatorFields>({
      resolver: zodResolver(validationSchema),
      mode: 'onBlur',
      defaultValues: {
        paymentCurrency: Currency.SINGAPORE,
      },
    });

  const watchedCountry = watch(COST_CALCULATOR_FORM_NAMES.country);
  const watchedPaymentCurreny = watch(
    COST_CALCULATOR_FORM_NAMES.paymentCurrency,
  ) as CurrencyType;
  const watchedContractType = watch(COST_CALCULATOR_FORM_NAMES.contractType);
  const watchedMonthlyGrossSalary = watch(
    COST_CALCULATOR_FORM_NAMES.monthlyGrossSalary,
  );

  const {
    data,
    isFetching: estimatedCostDataLoading,
    refetch: refetchEstimatedCost,
  } = useGetEstimatedCostQuery<GetEstimatedCostQuery, Error>(
    graphqlClient,
    {
      country: watchedCountry,
      currency: watchedPaymentCurreny,
      parameters: {
        grossSalary: {
          currency: Currency.INDONESIA,
          amount: watchedMonthlyGrossSalary,
        },
      },
    },
    {
      enabled: false,
      keepPreviousData: true,
      staleTime: 0,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (data) {
      setEstimatedCostData(data.estimatedCost as CostStructure);
    }
  }, [data]);

  const handleModalClose = () => {
    onClose?.();
    reset({
      [COST_CALCULATOR_FORM_NAMES.paymentCurrency]: Currency.SINGAPORE,
    });
    setEstimatedCostData(null);
  };

  const handleCountryChange = (value: string) => {
    reset({
      [COST_CALCULATOR_FORM_NAMES.country]: value,
      [COST_CALCULATOR_FORM_NAMES.paymentCurrency]: Currency.SINGAPORE,
    });
    setEstimatedCostData(null);
  };

  const handleCountryClear = () => {
    reset({
      [COST_CALCULATOR_FORM_NAMES.paymentCurrency]: Currency.SINGAPORE,
    });
    setEstimatedCostData(null);
  };

  const onSubmit: SubmitHandler<CostCalculatorFields> = async () => {
    await refetchEstimatedCost();
  };

  const handlePaymentCurrencyChange = () => {
    handleSubmit(onSubmit)();
  };

  const isSupportedCountry = SUPPORTED_COUNTRIES.includes(watchedCountry);

  return (
    <Styled.Modal
      isOpen={isOpen}
      header="Employment Cost Calculator"
      zIndexOverride={10}
      onClose={handleModalClose}
      {...props}
    >
      {estimatedCostData && (
        <Styled.Banner dismissable={false} status="warning" type="fixed">
          <Typography as="span" variant="body1" color={Neutral.B18}>
            Please note that these figures are estimates and may not include
            benefits such as commissions, bonuses, or allowances that could
            affect taxes.
          </Typography>
        </Styled.Banner>
      )}

      <Styled.FormContainer showBanner={Boolean(estimatedCostData)}>
        <Styled.Form onSubmit={handleSubmit(onSubmit)}>
          <Styled.Container>
            <Styled.Sider>
              <Card>
                <Styled.SiderCardContent>
                  <Styled.SiderCardSection>
                    <Card.Section>
                      <StepLabel stepNumber="1" label="Select Country" />
                      <FormSelect
                        control={control}
                        name={COST_CALCULATOR_FORM_NAMES.country}
                        label={
                          COST_CALCULATOR_FORM_LABEL[
                            COST_CALCULATOR_FORM_NAMES.country
                          ]
                        }
                        onSelect={(value: string) => handleCountryChange(value)}
                        onClear={handleCountryClear}
                        searchable={true}
                        showInputBadge={false}
                        options={countryOptions}
                        prefix={<Icon name="ri-search" />}
                      />
                    </Card.Section>
                  </Styled.SiderCardSection>

                  <Divider />

                  <Styled.SiderCardSection>
                    <Card.Section>
                      <StepLabel stepNumber="2" label="Provide Details" />
                      {isSupportedCountry ? (
                        <>
                          <Styled.Section>
                            <Box>
                              <Styled.FormLabel>
                                <Typography
                                  as="span"
                                  variant="subtitle2"
                                  color={Neutral.B18}
                                >
                                  Contract Type
                                </Typography>
                                <Tooltip
                                  content="Indonesia recognizes two fundamental types of employment contracts: Indefinite and Fixed-Term. Indefinite contracts, devoid of a specified termination date, endure until dissolution by either party. In contrast, Fixed-Term agreements are constrained by predetermined conclusion dates, concluding automatically upon their expiration."
                                  preferredPosition="top-center"
                                >
                                  <Icon
                                    name="ri-question-fill"
                                    height="16px"
                                    width="16px"
                                    fill={Neutral.B40}
                                  />
                                </Tooltip>
                              </Styled.FormLabel>
                              <FormSelect
                                control={control}
                                name={COST_CALCULATOR_FORM_NAMES.contractType}
                                options={contractTypeOptions}
                                onSelect={(value) => {
                                  if (value === CONTRACT_TYPE.indefinite) {
                                    setValue(
                                      COST_CALCULATOR_FORM_NAMES.contractPeriod,
                                      undefined,
                                      { shouldValidate: true },
                                    );
                                  }
                                }}
                                searchable={false}
                              />
                            </Box>
                            {watchedContractType ===
                              CONTRACT_TYPE.fixedTerm && (
                              <FormNumberInput
                                control={control}
                                label={
                                  COST_CALCULATOR_FORM_LABEL[
                                    COST_CALCULATOR_FORM_NAMES.contractPeriod
                                  ]
                                }
                                name={COST_CALCULATOR_FORM_NAMES.contractPeriod}
                                placeholder={
                                  COST_CALCULATOR_FORM_PLACEHOLDER[
                                    COST_CALCULATOR_FORM_NAMES.contractPeriod
                                  ]
                                }
                                helpText={
                                  COST_CALCULATOR_FORM_HELPTEXT[
                                    COST_CALCULATOR_FORM_NAMES.contractPeriod
                                  ]
                                }
                                suffix={FORM_NUMBER_INPUT_SUFFIX}
                              />
                            )}
                            <Box>
                              <Styled.FormLabel>
                                <Typography
                                  as="span"
                                  variant="subtitle2"
                                  color={Neutral.B18}
                                >
                                  Monthly Gross Salary
                                </Typography>
                                <Tooltip
                                  timeout={1500}
                                  content={
                                    <Box>
                                      <Typography
                                        variant="subtitle2"
                                        color={Neutral.B100}
                                      >
                                        Monthly gross salary is the total
                                        earnings an employee receives before
                                        deductions, encompassing taxes and other
                                        withholdings.
                                      </Typography>
                                      <Typography
                                        variant="subtitle2"
                                        color={Neutral.B100}
                                      >
                                        Unsure about the appropriate amount to
                                        pay? Explore our Team Expansion Budget
                                        Tool for guidance!
                                      </Typography>
                                      <PrimaryButton
                                        onClick={() =>
                                          window.open(
                                            TEAM_EXPANSION_URL,
                                            '_blank',
                                          )
                                        }
                                      >
                                        Try Tool
                                      </PrimaryButton>
                                    </Box>
                                  }
                                  preferredPosition="top-center"
                                >
                                  <Icon
                                    name="ri-question-fill"
                                    height="16px"
                                    width="16px"
                                    fill={Neutral.B40}
                                  />
                                </Tooltip>
                              </Styled.FormLabel>
                              <FormCurrencyInput
                                control={control}
                                name={
                                  COST_CALCULATOR_FORM_NAMES.monthlyGrossSalary
                                }
                                placeholder={
                                  COST_CALCULATOR_FORM_PLACEHOLDER[
                                    COST_CALCULATOR_FORM_NAMES
                                      .monthlyGrossSalary
                                  ]
                                }
                                helpText={
                                  COST_CALCULATOR_FORM_HELPTEXT[
                                    COST_CALCULATOR_FORM_NAMES
                                      .monthlyGrossSalary
                                  ]
                                }
                                currencySymbol="IDR"
                                currencyCode="IDR"
                              />
                            </Box>
                          </Styled.Section>
                        </>
                      ) : (
                        <Typography
                          as="span"
                          variant="subtitle2"
                          color={Neutral.B68}
                        >
                          Content will be displayed according to your selected
                          country of employment
                        </Typography>
                      )}
                    </Card.Section>
                  </Styled.SiderCardSection>
                </Styled.SiderCardContent>

                {isSupportedCountry && (
                  <Styled.SiderCardActions>
                    <Card.Section>
                      <PrimaryButton
                        type="submit"
                        disabled={!formState.isValid}
                      >
                        Calculate
                      </PrimaryButton>
                    </Card.Section>
                  </Styled.SiderCardActions>
                )}
              </Card>
            </Styled.Sider>

            <Styled.Main>
              <Styled.MainCardContainer>
                <Card>
                  {Boolean(watchedCountry) &&
                    isSupportedCountry &&
                    estimatedCostData && (
                      <>
                        <Styled.MainCardHeader>
                          <Styled.MainCardHeaderContent>
                            <Styled.MainCardHeaderContentTitle>
                              {
                                countryOptions.find(
                                  (country) => country.value === watchedCountry,
                                )?.icon
                              }
                              <Typography
                                as="div"
                                variant="body2"
                                color={Neutral.B18}
                              >
                                Estimation for{' '}
                                {
                                  countries.find(
                                    (country) =>
                                      country.value === watchedCountry,
                                  )?.label
                                }
                              </Typography>
                            </Styled.MainCardHeaderContentTitle>

                            <ButtonGroup segmented={true}>
                              <OutlineButton
                                active={isMonthly}
                                onClick={() => setIsMonthly(true)}
                                type="button"
                              >
                                Monthly
                              </OutlineButton>
                              <OutlineButton
                                active={!isMonthly}
                                onClick={() => setIsMonthly(false)}
                                type="button"
                              >
                                Annual
                              </OutlineButton>
                            </ButtonGroup>
                          </Styled.MainCardHeaderContent>
                        </Styled.MainCardHeader>

                        <Styled.CurrencySelectContainer>
                          <FormPaymentCurrencySelect
                            control={control}
                            name={COST_CALCULATOR_FORM_NAMES.paymentCurrency}
                            onCurrencyChange={handlePaymentCurrencyChange}
                          />
                        </Styled.CurrencySelectContainer>

                        {!estimatedCostDataLoading ? (
                          <ComputationResult
                            estimatedCost={estimatedCostData}
                            paymentCurrency={watchedPaymentCurreny}
                            isMonthly={isMonthly}
                          />
                        ) : (
                          LoadingState
                        )}
                      </>
                    )}
                  {!estimatedCostData &&
                    (Boolean(watchedCountry) && !isSupportedCountry
                      ? UnderDevelopmentState
                      : NoEstimateEmptyState)}
                </Card>
              </Styled.MainCardContainer>
            </Styled.Main>
          </Styled.Container>
        </Styled.Form>
      </Styled.FormContainer>

      <Styled.ModalArt src={AstronautImage} />
    </Styled.Modal>
  );
};
